import axios from 'axios';
import { get, post, patch } from '../../../api'
import { storeError } from '../general/actions'
import { ApiEndPoint, DEFAULT_LANGUAGE, LANGUAGES } from '../../../settings/constants'
import { getUser } from './reducer'
import { getImage } from '../media/reducer'
import { getToken } from '../login/reducer'

const actions = {
  FETCH_USERS: "FETCH_USERS",
  FETCH_USER: "FETCH_USER",
  FETCH_USER_ITEMS: "FETCH_USER_ITEMS",
  ADD_USER: "ADD_USER",
  EDIT_USER: "EDIT_USER",
  REMOVE_USER: "REMOVE_USER",
  CLEAR_USER: "CLEAR_USER",
  clearUser: () => {
    return { type: actions.CLEAR_USER };
  },
  fetchUser: (userId, updateList = false) => {
    return function (dispatch, getState) {
      const onSuccess = (data) => { return _fetchUser(data, updateList) }
      get(`${ApiEndPoint}/admins/${userId}`, null, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  fetchUsers: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/admins`, null, _fetchUsers, storeError, false)(dispatch, getState)
    }
  },
  fetchUserItems: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/admins`, null, _fetchUserItems, storeError, false)(dispatch, getState)
    }
  },
  addUser: (values) => {
    return function (dispatch, getState) {
      const user = { ...values, creation_date: Date.now(), type: 'user' }
      user.name = user.username
      if (user.languages) {
        user.languages.active = LANGUAGES
      } else {
        user.languages = { prefered: DEFAULT_LANGUAGE, active: LANGUAGES }
      }
      const onSuccess = (data) => { return [_fetchUser(data, true)] }
      post(`${ApiEndPoint}/admins`, user, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  modifyUser: (values) => {
    return function (dispatch, getState) {
      if (!values.has_time) {
        if (values.from_date) values.from_date = new Date(values.from_date).setHours(0, 0, 0, 0)
        if (values.to_date) values.to_date = new Date(values.to_date).setHours(0, 0, 0, 0)
      }
      const data = { ...values, update_date: Date.now() }
      /*
      const data = {
        id: values.id,
        email: values.email,
        password: values.password,
        data: { ...values, update_date: Date.now() }
      }*/
      const onSuccess = (data) => { return [_fetchUser(data, true)] }
      patch(`${ApiEndPoint}/admins/${values.id}`, data, onSuccess, storeError)(dispatch, getState)
    }
  },
  addUserLanguage: (language) => {
    return function (dispatch, getState) {
      let user = getUser(getState())
      if (user.languages) {
        if (user.languages.active) {
          if (!user.languages.active.includes(language)){
            user.languages.active.push(language)
          }
        } else {
          user.languages.active = [language]
        }
      } else {
        user.languages = { prefered: language, active: [language] }
      }
      actions.modifyUser(user)(dispatch, getState)
    }
  },
  removeUserLanguage: (language) => {
    return function (dispatch, getState) {
      let user = getUser(getState())
      if (user.languages) {
        if (user.languages.active) {
          user.languages.active = user.languages.active.filter(item => item !== language)
        }
      }
      actions.modifyUser(user)(dispatch, getState)
    }
  },
  modifyUserHeader: (title, content, permalink) => {
    return function (dispatch, getState) {
      let user = getUser(getState())
      user = { ...user, title, content, permalink }
      actions.modifyUser(user)(dispatch, getState)
    }
  },
  modifyUserImage: () => {
    return function (dispatch, getState) {
      let user = getUser(getState())
      let images = getImage(getState())
      user = { ...user, images }
      actions.modifyUser(user)(dispatch, getState)
    }
  },
  addTaxonomyToUser: (taxonomyId) => {
    return function (dispatch, getState) {
      const user = getUser(getState())
      user.categories ? user.categories.push(taxonomyId) : user.categories = [taxonomyId]
      actions.modifyUser(user)(dispatch, getState)
    }
  },
  removeTaxonomyFromUser: (taxonomyId) => {
    return function (dispatch, getState) {
      const user = getUser(getState())
      user.categories = user.categories.filter(id => id !== taxonomyId)
      actions.modifyUser(user)(dispatch, getState)
    }
  },
  modifyUserLocations: (locations) => {
    return function (dispatch, getState) {
      const user = getUser(getState())
      user.locations = locations
      actions.modifyUser(user)(dispatch, getState)
    }
  },
  modifyUserLanguage: (language, translations) => {
    return function (dispatch, getState) {
      const user = getUser(getState())
      if (!user.multilanguage) user.multilanguage = {}
      user.multilanguage[language] = { ...translations }
      actions.modifyUser(user)(dispatch, getState)
    }
  },
  removeUser: (userId) => {
    return function (dispatch, getState) {
      const access_token = getToken(getState())
      axios.delete(`${ApiEndPoint}/admins/${userId}?access_token=${access_token}`)
        .then(function () {
          dispatch(_removeUser(userId))
        })
    }
  }
}

function _fetchUser (data, updateList) { return { type: actions.FETCH_USER, updateList, payload: data } }
function _fetchUsers (data) { return { type: actions.FETCH_USERS, payload: data } }
function _fetchUserItems (data) { return { type: actions.FETCH_USER_ITEMS, payload: data } }
function _removeUser (id) { return { type: actions.REMOVE_USER, payload: id } }

export default actions
