import axios from 'axios';
import { get, post, put } from '../../../api'
import { storeError } from '../general/actions'
import { ApiEndPoint } from '../../../settings/constants'
import { DEFAULT_LANGUAGE } from '../../../settings/constants'
import { getLandmark } from './reducer'
import { getImage } from '../media/reducer'
import { getToken } from '../login/reducer'

const actions = { 
  FETCH_LANDMARKS: "FETCH_LANDMARKS",
  FETCH_LANDMARK: "FETCH_LANDMARK",
  FETCH_LANDMARK_ITEMS: "FETCH_LANDMARK_ITEMS",
  ADD_LANDMARK: "ADD_LANDMARK",
  EDIT_LANDMARK: "EDIT_LANDMARK",
  REMOVE_LANDMARK: "REMOVE_LANDMARK",
  CLEAR_LANDMARK: "CLEAR_LANDMARK",
  clearLandmark: () => {
    return { type: actions.CLEAR_LANDMARK };
  },
  fetchLandmark: (landmarkId, updateList = false) => {
    return function (dispatch, getState) {
      const onSuccess = (data) => { return _fetchLandmark(data, updateList) }
      get(`${ApiEndPoint}/landmarks/${landmarkId}`, null, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  fetchLandmarks: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/landmarks`, null, _fetchLandmarks, storeError, false)(dispatch, getState)
    }
  },
  fetchLandmarkItems: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/landmarks`, null, _fetchLandmarkItems, storeError, false)(dispatch, getState)
    }
  },
  addLandmark: (values) => {
    return function (dispatch, getState) {
      const landmark = { ...values, language: DEFAULT_LANGUAGE, creation_date: Date.now(), type: 'landmark' }
      const onSuccess = (data) => { return [_fetchLandmark(data, true)] }
      post(`${ApiEndPoint}/landmarks`, landmark, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  modifyLandmark: (values) => {
    return function (dispatch, getState) {
      if (!values.has_time) {
        if (values.from_date) values.from_date = new Date(values.from_date).setHours(0, 0, 0, 0)
        if (values.to_date) values.to_date = new Date(values.to_date).setHours(0, 0, 0, 0)
      }
      const landmark = { ...values, update_date: Date.now() }
      const onSuccess = (data) => { return [_fetchLandmark(data, true)] }
      put(`${ApiEndPoint}/landmarks/${landmark.id}`, landmark, onSuccess, storeError)(dispatch, getState)
    }
  },
  modifyLandmarkDefaultLanguage: (language) => {
    return function (dispatch, getState) {
      let landmark = getLandmark(getState())
      landmark.language = language
      if (landmark.multilanguage) {
        delete (landmark.multilanguage[language])
      }
      actions.modifyLandmark(landmark)(dispatch, getState)
    }
  },
  modifyLandmarkHeader: (title, content, permalink) => {
    return function (dispatch, getState) {
      let landmark = getLandmark(getState())
      landmark = { ...landmark, title, content, permalink }
      actions.modifyLandmark(landmark)(dispatch, getState)
    }
  },
  modifyLandmarkImage: () => {
    return function (dispatch, getState) {
      let landmark = getLandmark(getState())
      let images = getImage(getState())
      landmark = { ...landmark, images }
      actions.modifyLandmark(landmark)(dispatch, getState)
    }
  },
  addTaxonomyToLandmark: (taxonomyId) => {
    return function (dispatch, getState) {
      const landmark = getLandmark(getState())
      landmark.categories ? landmark.categories.push(taxonomyId) : landmark.categories = [taxonomyId]
      actions.modifyLandmark(landmark)(dispatch, getState)
    }
  },
  removeTaxonomyFromLandmark: (taxonomyId) => {
    return function (dispatch, getState) {
      const landmark = getLandmark(getState())
      landmark.categories = landmark.categories.filter(id => id !== taxonomyId)
      actions.modifyLandmark(landmark)(dispatch, getState)
    }
  },
  modifyLandmarkLocations: (locations) => {
    return function (dispatch, getState) {
      const landmark = getLandmark(getState())
      landmark.locations = locations
      actions.modifyLandmark(landmark)(dispatch, getState)
    }
  },
  modifyLandmarkLanguage: (language, translations) => {
    return function (dispatch, getState) {
      const landmark = getLandmark(getState())
      if (!landmark.multilanguage) landmark.multilanguage = {}
      landmark.multilanguage[language] = { ...translations }
      actions.modifyLandmark(landmark)(dispatch, getState)
    }
  },
  removeLandmark: (landmarkId) => {
    return function (dispatch, getState) {
      const access_token = getToken(getState())
      axios.delete(`${ApiEndPoint}/landmarks/${landmarkId}?access_token=${access_token}`)
        .then(function () {
          dispatch(_removeLandmark(landmarkId))
        })
    }
  },
  findLandmarks: (searchParameters) => {
    return function (dispatch, getState) {
      const parameters = {}
      if(searchParameters.title) {
          parameters.title = {
            like: searchParameters.title,
            options: "i"
          }
      }
      if (searchParameters.location) {
        parameters.locations = { in: [searchParameters.location] }
      }
      if (searchParameters.language) {
        parameters.language = { "eq": searchParameters.language }
      }
      if (searchParameters.status) {
        parameters.status = { "regexp": searchParameters.status }
      }
      get(`${ApiEndPoint}/landmarks`, { "filter": { "where": { ...parameters }, "order": 'id DESC' } }, _fetchLandmarks, storeError, false)(dispatch, getState)


    }
  }
}

function _fetchLandmark (data, updateList) { return { type: actions.FETCH_LANDMARK, updateList, payload: data } }
function _fetchLandmarks (data) { return { type: actions.FETCH_LANDMARKS, payload: data } }
function _fetchLandmarkItems (data) { return { type: actions.FETCH_LANDMARK_ITEMS, payload: data } }
function _removeLandmark (id) { return { type: actions.REMOVE_LANDMARK, payload: id } }

export default actions
