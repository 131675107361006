import { Map } from 'immutable'
import actions from "./actions"
import { getLastModified } from '../utils'


const initState = new Map({
  taxonomies: null
});

export default function taxonomiesReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_TAXONOMY:
      return _setTaxonomy(state, action.api, action.payload, action.updateList)
    case actions.FETCH_TAXONOMY_ITEMS:
      return state.set('taxonomy_items_' + action.api, _toItems(action.payload))
    case actions.CLEAR_TAXONOMY:
      return state.set('taxonomy_' + action.api, null)
    case actions.FETCH_TAXONOMIES:
      return state.set('taxonomies_' + action.api, action.payload)
    case actions.REMOVE_TAXONOMY:
      return _deleteElement(state, action.api, action.payload)
    default:
      return state;
  }
}

function _deleteElement (state, api, id) {
  const items = JSON.parse(JSON.stringify(state.get('taxonomy_items_'+ api)))
  
  if (items && id) {  
    const foundIndex = items.findIndex(itemInList => itemInList.code === id)    
    if (foundIndex > -1) {
      items.splice(foundIndex, 1)
    }
    return state.set('taxonomies_' + api, items).set('taxonomy_items_'+ api, items)
  }
  
}

function _setTaxonomy (state, api, item, updateList) {
  const items = state.get('taxonomies_' + api)
  if (updateList && items) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === item.id)
    if (foundIndex > -1) {
      items[foundIndex] = item
    } else {
      items.push(item)
    }
    return state.set('taxonomy_' + api, item)
                .set('taxonomies_' + api, items)
                .set('taxonomy_items_' + api, items)
  } else {
    const items = state.get('taxonomy_items_' + api)
    if (updateList && items) {
      const foundIndex = items.findIndex(itemInList => itemInList.code === item.id)
      if (foundIndex > -1) {
        items[foundIndex] = _toItem(item)
      } else {
        items.push(_toItem(item))
      }
      return state.set('taxonomy_' + api, item).set('taxonomy_items_' + api, items)
    } else {
      return state.set('taxonomy_' + api, item)
    }
  }
}

function _toItems (taxonomies) {
  let items = []
  if (taxonomies) {
    taxonomies.forEach(taxonomy => {
      items.push(_toItem(taxonomy))
    })
  }
  return items
}

function _toItem(taxonomy) {
  if (taxonomy.parent_id === 0) { taxonomy.parent_id = '0' }
  return { code: taxonomy.id, name: taxonomy.title, permalink: taxonomy.permalink, parentItemCode: taxonomy.parent_id, language: taxonomy.language, multilanguage: taxonomy.multilanguage }
}

export function getTaxonomies (state, api) {
  return state.Taxonomies.get('taxonomies_' + api)
}

export function getTaxonomy (state, api) {
  return state.Taxonomies.get('taxonomy_' + api)
}

export function getTaxonomyItems (state, api) {
  // api = 'eventCategory', 'locations' ...
  return state.Taxonomies.get('taxonomy_items_' + api)
}

export function getFirstLevelLocationItems (state) {
  const locations = state.Taxonomies.get('taxonomy_items_locations')
  if (locations) {
    return locations.filter((location) => 
    location.parentItemCode === '0')
  }
}

export function getTaxonomyFromList (state, api, id) {
  const taxonomies = getTaxonomies(state, api)
  if (taxonomies) {
    var map = taxonomies
    return map.find(function (taxonomy) { return taxonomy.get('id') === id });
  }
  return null
}