import { Map } from 'immutable'
import actions from './actions'
import { getLastModified } from '../utils'

const initState = new Map({
  landmarks: null
});

export default function landmarksReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_LANDMARK:
      return _setLandmark(state, action.payload, action.updateList)
    case actions.CLEAR_LANDMARK:
      return state.set('landmark', null);
    case actions.FETCH_LANDMARKS:
      return state.set('landmarks', getLastModified(action.payload))
    case actions.FETCH_LANDMARK_ITEMS:
      return state.set('landmarkItems', _toItems(action.payload))
    case actions.REMOVE_LANDMARK:
      return _deleteElement(state, action.payload)
    default:
      return state;
  }
}

export function _toItems(landmarks) {
  let items = []
  if (landmarks) {
    landmarks.forEach(landmark => {
      items.push({ value: landmark.id, label: landmark.title })
    })
  }
  return items
}

function _setLandmark (state, item, updateList) {
  const items = JSON.parse(JSON.stringify(state.get('landmarks')))
  if (updateList && items) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === item.id)
    if (foundIndex > -1) {
      items[foundIndex] = item
    } else {
      items.push(item)
    }
    return state.set('landmark', item).set('landmarks', getLastModified(items))
  } else {
    return state.set('landmark', item)
  }
}

function _deleteElement (state, id) {
  const items = JSON.parse(JSON.stringify(state.get('landmarks')))
  if (items && id) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === id)
    if (foundIndex > -1) {
      items.splice(foundIndex, 1)
    }
    return state.set('landmarks', getLastModified(items))
  }
}

export function getLandmarkItems (state) {
  return state.Landmarks.get('landmarkItems')
}

export function getLandmarks (state) {
  return state.Landmarks.get('landmarks')
}

export function getLandmark(state) {
  return state.Landmarks.get('landmark')
}

export function getLandmarkFromList (state, landmarkId) {
  const landmarks = getLandmarks(state)
  if (landmarks) {
    var map = landmarks
    var landmark = map.find(function(landmark) { return landmark.get('id') === landmarkId })
    return landmark
  }
  return null
}