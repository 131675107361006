import { Map } from 'immutable'
import actions from './actions'
import { getLastModified } from '../utils'

const initState = new Map({
  users: null
});

export default function usersReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_USER:
      return _setUser(state, action.payload, action.updateList)
    case actions.CLEAR_USER:
      return state.set('user', null);
    case actions.FETCH_USERS:
      return state.set('users', getLastModified(action.payload))
    case actions.FETCH_USER_ITEMS:
      return state.set('userItems', _toItems(action.payload))
    case actions.REMOVE_USER:
      return _deleteElement(state, action.payload)
    default:
      return state;
  }
}

export function _toItems(users) {
  let items = []
  if (users) {
    users.forEach(user => {
      items.push({ value: user.id, label: user.title })
    })
  }
  return items
}

function _setUser (state, item, updateList) {
  const items = JSON.parse(JSON.stringify(state.get('users')))
  if (updateList && items) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === item.id)
    if (foundIndex > -1) {
      items[foundIndex] = item
    } else {
      items.push(item)
    }
    return state.set('user', item).set('users', getLastModified(items))
  } else {
    return state.set('user', item)
  }
}

function _deleteElement (state, id) {
  const items = JSON.parse(JSON.stringify(state.get('users')))
  if (items && id) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === id)
    if (foundIndex > -1) {
      items.splice(foundIndex, 1)
    }
    return state.set('users', getLastModified(items))
  }
}

export function getUserItems (state) {
  return state.Users.get('userItems')
}

export function getUsers (state) {
  return state.Users.get('users')
}

export function getUser(state) {
  return state.Users.get('user')
}

export function getUserFromList (state, userId) {
  const users = getUsers(state)
  if (users) {
    var map = users
    var user = map.find(function(user) { return user.id === userId })
    return user
  }
  return null
}