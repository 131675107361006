import { Map } from 'immutable'
import { START_LOADING, END_LOADING, STORE_ERROR } from './actions'

/**
 * Reducer para el almacenamiento de datos generales de la aplicación.
 *
 * @param {Object} state - estado de redux
 * @param {Object} action - acción a ejecutar
 */
export default function (state = new Map(), action) {
  switch (action.type) {
    case START_LOADING:
      return state.set('loading', true)
    case END_LOADING:
      return state.set('loading', false)
    case STORE_ERROR:
      return state.set('error', action.payload)
  }

  return state
}

/**
 * Indica si hay alguna petición en marcha (cargando)
 *
 * @param {Object} state - Estado general
 * @return {boolean} - true / false según si está cargando o no.
 */
export function isLoading (state) {
  return state.General.get('loading')
}