import { getDefaultPath } from '../../helpers/urlSync';
import { ADMIN, TRANSLATOR } from '../../settings/constants';

const options = [
  {
    label: 'sidebar.dashboard',
    key: 'dashboard',
    withoutDashboard: true,
    leftIcon: "dashboard",
    permits: [ADMIN, TRANSLATOR]
  },
  {
    label: 'sidebar.events',
    key: 'events',
    leftIcon: "event",
    permits: [ADMIN, TRANSLATOR]
  },
  {
    label: 'sidebar.landmarks',
    key: 'landmarks',
    leftIcon: "room",
    permits: [ADMIN, TRANSLATOR]
  },
  {
    label: 'sidebar.routes',
    key: 'routes',
    leftIcon: "map",
    permits: [ADMIN, TRANSLATOR]
  },
  {
    label: 'sidebar.locations',
    key: 'locations',
    leftIcon: "terrain",
    permits: [ADMIN, TRANSLATOR]
  },
  {
    label: 'sidebar.tips',
    key: 'tips',
    leftIcon: "wb_incandescent",
    permits: [ADMIN, TRANSLATOR]
  },
  {
    label: 'sidebar.ads',
    key: 'ads',
    leftIcon: "photo_size_select_large",
    permits: [ADMIN]
  },
  {
    label: 'sidebar.taxonomies',
    key: 'taxonomies',
    leftIcon: "receipt",
    permits: [ADMIN],
    children: [
      {
        label: "sidebar.events.categories",
        key: "event-categories"
      },
      {
        label: "sidebar.landmarks.categories",
        key: "landmark-categories"
      },
      {
        label: "sidebar.routes.categories",
        key: "route-categories"
      }
    ]
  },
  {
    label: 'sidebar.translations',
    key: 'translations',
    leftIcon: "translate",
    permits: [ADMIN, TRANSLATOR],
    children: [
      {
        label: "sidebar.events.translations",
        key: "events/translations"
      },
      {
        label: "sidebar.event-categories.translations",
        key: "event-categories/translations"
      },
      {
        label: "sidebar.landmarks.translations",
        key: "landmarks/translations"
      },
      {
        label: "sidebar.landmark-categories.translations",
        key: "landmark-categories/translations"
      },
      {
        label: "sidebar.routes.translations",
        key: "routes/translations"
      },
      {
        label: "sidebar.route-categories.translations",
        key: "route-categories/translations"
      },
      {
        label: "sidebar.tips.translations",
        key: "tips/translations"
      },
      {
        label: "sidebar.ads.translations",
        key: "ads/translations"
      }
    ]
  },
  {
    label: 'sidebar.clients',
    key: 'clients',
    leftIcon: "group",
    permits: [ADMIN]
  },
  {
    label: 'sidebar.widgets',
    key: 'widgets',
    leftIcon: "picture_in_picture",
    permits: [ADMIN]
  },
  {
    label: 'sidebar.todos',
    key: 'todos',
    leftIcon: "list_alt",
    permits: [ADMIN, TRANSLATOR]
  },
  {
    label: 'sidebar.users',
    key: 'users',
    leftIcon: "face",
    permits: [ADMIN]
  },
  {
    label: 'sidebar.integrations',
    key: 'integrations',
    leftIcon: "share",
    permits: [ADMIN]
  },
  {
    label: 'sidebar.reports',
    key: 'reports',
    leftIcon: 'assignment',
    permits: [ADMIN],
    children: [
      {
        label: "sidebar.reports.export",
        key: "reports/export"
      }
    ]
  },
  {
    label: 'sidebar.statistics',
    key: 'statistics',
    leftIcon: 'assignment',
    permits: [ADMIN],
    // children: [
    //   {
    //     label: "sidebar.reports.dashboard",
    //     key: "statistics"
    //   }
    // ]
  },
]

const getBreadcrumbOption = () => {
  const preKeys = getDefaultPath();
  let parent, activeChildren;
  options.forEach(option => {
    if (preKeys[option.key]) {
      parent = option;
      (option.children || []).forEach(child => {
        if (preKeys[child.key]) {
          activeChildren = child;
        }
      });
    }
  });
  return { parent, activeChildren };
};
export default options;
export { getBreadcrumbOption };
