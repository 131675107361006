import { Map } from 'immutable'
import actions from "./actions"

const initState = new Map({
  dashboardData: null
});

export default function dashboardReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_DASHBOARD:
      return state.set('dashboardData', action.payload);
    default:
      return state;
  }
}

export function getDashboardData (state) {
  return state.Dashboard.get('dashboardData')
}

