import axios from 'axios';
import { get, post, put } from '../../../api'
import { storeError } from '../general/actions'
import { ApiEndPoint } from '../../../settings/constants'
import { DEFAULT_LANGUAGE } from '../../../settings/constants'
import { getTip } from './reducer'
import { getImage } from '../media/reducer'
import { getToken } from '../login/reducer'

const actions = {
  FETCH_TIPS: "FETCH_TIPS",
  FETCH_TIP: "FETCH_TIP",
  ADD_TIP: "ADD_TIP",
  EDIT_TIP: "EDIT_TIP",
  REMOVE_TIP: "REMOVE_TIP",
  CLEAR_TIP: "CLEAR_TIP",
  clearTip: () => {
    return { type: actions.CLEAR_TIP };
  },
  fetchTip: (tipId, updateList = false) => {
    return function (dispatch, getState) {
      const onSuccess = (data) => { return _fetchTip(data, updateList) }
      get(`${ApiEndPoint}/tips/${tipId}`, null, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  fetchTips: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/tips`, null, _fetchTips, storeError, false)(dispatch, getState)
    }
  },
  addTip: (values) => {
    return function (dispatch, getState) {
      if (values.start_date) values.start_date = new Date(values.start_date).setHours(0, 0, 0, 0)
      if (values.end_date) values.end_date = new Date(values.end_date).setHours(0, 0, 0, 0)
      const tip = { ...values, language: DEFAULT_LANGUAGE, creation_date: Date.now(), type: 'tip' }
      const onSuccess = (data) => { return [_fetchTip(data, true)] }
      post(`${ApiEndPoint}/tips`, tip, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  modifyTip: (values) => {
    return function (dispatch, getState) {
      if (values.start_date) values.start_date = new Date(values.start_date).setHours(0, 0, 0, 0)
      if (values.end_date) values.end_date = new Date(values.end_date).setHours(0, 0, 0, 0)
      const tip = { ...values, update_date: Date.now() }
      const onSuccess = (data) => { return [_fetchTip(data, true)] }
      put(`${ApiEndPoint}/tips/${tip.id}`, tip, onSuccess, storeError)(dispatch, getState)
    }
  },
  modifyTipDefaultLanguage: (language) => {
    return function (dispatch, getState) {
      let tip = getTip(getState())
      tip.language = language
      if (tip.multilanguage) {
        delete (tip.multilanguage[language])
      }
      actions.modifyTip(tip)(dispatch, getState)
    }
  },
  modifyTipHeader: (title, content, permalink) => {
    return function (dispatch, getState) {
      let tip = getTip(getState())
      tip = { ...tip, title, content, permalink }
      actions.modifyTip(tip)(dispatch, getState)
    }
  },
  modifyTipImage: () => {
    return function (dispatch, getState) {
      let tip = getTip(getState())
      let images = getImage(getState())
      tip = { ...tip, images }
      actions.modifyTip(tip)(dispatch, getState)
    }
  },
  addTaxonomyToTip: (taxonomyId) => {
    return function (dispatch, getState) {
      const tip = getTip(getState())
      tip.categories ? tip.categories.push(taxonomyId) : tip.categories = [taxonomyId]
      actions.modifyTip(tip)(dispatch)
    }
  },
  removeTaxonomyFromTip: (taxonomyId) => {
    return function (dispatch, getState) {
      const tip = getTip(getState())
      tip.categories = tip.categories.filter(id => id !== taxonomyId)
      actions.modifyTip(tip)(dispatch)
    }
  },
  modifyTipLocations: (locations) => {
    return function (dispatch, getState) {
      const tip = getTip(getState())
      tip.locations = locations
      actions.modifyTip(tip)(dispatch)
    }
  },
  modifyTipLanguage: (language, translations) => {
    return function (dispatch, getState) {
      const tip = getTip(getState())
      if (!tip.multilanguage) tip.multilanguage = {}
      tip.multilanguage[language] = { ...translations }
      actions.modifyTip(tip)(dispatch, getState)
    }
  },
  removeTip: (tipId) => {
    return function (dispatch, getState) {
      const access_token = getToken(getState())
      axios.delete(`${ApiEndPoint}/tips/${tipId}?access_token=${access_token}`)
        .then(function () {
          dispatch(_removeTip(tipId))
        })
    }
  }
}

function _fetchTip (data, updateList) { return { type: actions.FETCH_TIP, updateList, payload: data } }
function _fetchTips (data) { return { type: actions.FETCH_TIPS, payload: data } }
function _removeTip (id) { return { type: actions.REMOVE_TIP, payload: id } }

export default actions