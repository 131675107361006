import { Map } from 'immutable'
import { UPLOAD, CLEAR, SET_COPIED_IMAGE_URL } from './actions'

/**
 * Reducer para el almacenamiento de datos generales de la aplicación.
 *
 * @param {Object} state - estado de redux
 * @param {Object} action - acción a ejecutar
 */
export default function (state = new Map(), action) {
  
  switch (action.type) {
    case UPLOAD:
      //console.log('ACTION REDUX', action.payload);
      //console.log('state',state);
       return state.set('image', action.payload)
    case CLEAR:
       return state.set('image', null)
    case SET_COPIED_IMAGE_URL:
       return state.set('copiedImageUrl', action.payload)
  }

  return state;

}

/**
 * Devuelve la última imagen subida
 *
 * @param {Object} state - Estado general
 * @return {Object} - URLs de los diferentes formatos de la imagen
 */
export function getImage (state) {
  ////console.log('getImage state',state);
  if (state.Media) {
    const image = state.Media.get('image')
    console.log('getimage image', image);
    ////console.log('typeof image', typeof image);
    if (image) {
      return {
        image_thumbnail_url: image.thumbnail,
        image_medium_url: image.medium,
        image_large_url: image.large
      }
    }
  } else {
    return null
  }

}

export function getCopiedImageUrl (state) {
  if (state.Media) {
  return state.Media.get('copiedImageUrl')
  }
}