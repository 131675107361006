import { Map } from 'immutable'
import actions from './actions'
import { getLastModified } from '../utils'

const initState = new Map({
  events: null
});

export default function eventsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_EVENT:
      return _setEvent(state, action.payload, action.updateList)
    case actions.CLEAR_EVENT:
      return state.set('event', null)
    case actions.FETCH_EVENTS:
      return state.set('events', action.payload) //getLastModified(action.payload))
    case actions.REMOVE_EVENT:
      return _deleteElement(state, action.payload)
    case actions.RESET_COUNTERS:
      return state
      .setIn(['event_treated', action.payload], 0)
      .setIn(['event_created', action.payload], 0)
      .setIn(['event_duplicated', action.payload], 0)
    case actions.COUNT_EVENT_TREATED:
      const treated = state.getIn(['event_treated', action.payload])
      return state.setIn(['event_treated', action.payload], treated + 1)
    case actions.COUNT_EVENT_CREATED:
      const created = state.getIn(['event_created', action.payload])
      return state.setIn(['event_created', action.payload], created + 1)
    case actions.COUNT_EVENT_DUPLICATED:
      const duplicated = state.getIn(['event_duplicated', action.payload])
      return state.setIn(['event_duplicated', action.payload], duplicated + 1)
    case actions.REMOVE_MANY_EVENTS:
      //console.log('reducer REMOVE MANY EVENTS', action.payload)
    return _deleteManyElements(state, action.payload)
    default:
      return state
  }
}

function _setEvent (state, item, updateList) {
  
  const items = JSON.parse(JSON.stringify(state.get('events')))
  if (updateList && items) {
    

    const foundIndex = items.findIndex(itemInList => itemInList.id === item.id)
    
    if (foundIndex > -1) {
      items[foundIndex] = item
    } else {
     
      items.push(item)
    }
    return state.set('event', item).set('events', items)
  } else {
    return state.set('event', item)
  }
}

function _deleteElement (state, id) {
  const items = JSON.parse(JSON.stringify(state.get('events')))
  if (items && id) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === id)
    if (foundIndex > -1) {
      items.splice(foundIndex, 1)
    }
    return state.set('events', getLastModified(items))
  }
}

function _deleteManyElements (state, ids) {
  const items = JSON.parse(JSON.stringify(state.get('events')))
  if (items && ids) {
    ids.map(id => {
      const foundIndex = items.findIndex(itemInList => itemInList.id === id)
      if (foundIndex > -1) {
        items.splice(foundIndex, 1)
      }
    })
    return state.set('events', getLastModified(items))
  }
}

export function getEvents (state) {
  return state.Events.get('events')
}

export function getEvent (state) {
  return state.Events.get('event')
}

export function getTreatedCounter (state) {
  return state.Events.get('event_treated')
}

export function getCreatedCounter (state) {
  return state.Events.get('event_created')
}

export function getDuplicatedCounter (state) {
  return state.Events.get('event_duplicated')
}

export function getEventFromList (state, eventId) {
  const events = getEvents(state)
  if (events) {
    var map = events
    var event = map.find(function(event) { return event.get('id') === eventId })
    return event
  }
  return null
}