import axios from 'axios';
import { get, post, put } from '../../../api'
import { storeError } from '../general/actions'
import { ApiEndPoint } from '../../../settings/constants'
import { DEFAULT_LANGUAGE } from '../../../settings/constants'
import { getToken } from '../login/reducer'
import { getTaxonomy } from './reducer'

const actions = {
  FETCH_TAXONOMIES: "FETCH_TAXONOMIES",
  FETCH_TAXONOMY: "FETCH_TAXONOMY",
  FETCH_TAXONOMY_ITEMS: "FETCH_TAXONOMY_ITEMS",
  ADD_TAXONOMY: "ADD_TAXONOMY",
  EDIT_TAXONOMY: "EDIT_TAXONOMY",
  REMOVE_TAXONOMY: "REMOVE_TAXONOMY",
  CLEAR_TAXONOMY: "CLEAR_TAXONOMY",
  clearTaxonomy: (api) => {
    return { api, type: actions.CLEAR_TAXONOMY };
  },
  fetchTaxonomy: (api, id, updateList = false) => {
    return function (dispatch, getState) {
      const onSuccess = (data) => { return _fetchTaxonomy(api, data, updateList) }
      get(`${ApiEndPoint}/${api}/${id}`, null, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  fetchTaxonomies: (api) => {
    return function (dispatch, getState) {
      const onSuccess = (data) => { return _fetchTaxonomies(api, data) }
      get(`${ApiEndPoint}/${api}`, null, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  fetchTaxonomyItems: (api) => {
    return function (dispatch, getState) {
      const onSuccess = (data) => { return _fetchTaxonomyItems(api, data) }
      get(`${ApiEndPoint}/${api}`, null, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  addTaxonomy: (api, type, values) => {
    return function (dispatch, getState) {
      const taxonomy = { ...values, creation_date: Date.now(), language: DEFAULT_LANGUAGE, type, class_type: 'taxonomy' }
      const onSuccess = (data) => { return [actions.fetchTaxonomy(api, data.id), actions.fetchTaxonomyItems(api), actions.fetchTaxonomies(api, true)] }
      post(`${ApiEndPoint}/${api}`, taxonomy, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  modifyTaxonomy: (api, values) => {
    return function (dispatch, getState) {
      const taxonomy = { ...values, update_date: Date.now() }
      const onSuccess = (data) => { return _fetchTaxonomy(api, data, true) }
      put(`${ApiEndPoint}/${api}/${taxonomy.id}`, taxonomy, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  modifyTaxonomyHeader: (api, title, content, permalink) => {
    return function (dispatch, getState) {
      let taxonomy = getTaxonomy(getState(), api)
      taxonomy = { ...taxonomy, title, content, permalink }
      actions.modifyTaxonomy(api, taxonomy)(dispatch, getState)
    }
  },
  modifyTaxonomyLanguage: (api, language, translations) => {
    return function (dispatch, getState) {
      let taxonomy = getTaxonomy(getState(), api)
      if (!taxonomy.multilanguage) taxonomy.multilanguage = {}
      taxonomy.multilanguage[language] = { ...translations }
      actions.modifyTaxonomy(api, taxonomy)(dispatch, getState)
    }
  },
  removeTaxonomy: (api, id) => {
    console.log("EJECUTAMOS removeTaxonomy", api, id)

    if(!id){

      let idSplited = api.split("/")
      console.log(idSplited[0])

      return function (dispatch, getState) {
        const access_token = getToken(getState())
        axios.delete(`${ApiEndPoint}/${api}?access_token=${access_token}`)
          .then(function () {
            dispatch(actions.fetchTaxonomies(idSplited[0]))
          })
      }
      
    }
    return function (dispatch, getState) {
      const access_token = getToken(getState())
      axios.delete(`${ApiEndPoint}/${api}/${id}?access_token=${access_token}`)
        .then(function () {
          //dispatch(actions.fetchTaxonomies(api))
          dispatch(_removeTaxonomy(api, id))
        })
    }
  }
}

function _fetchTaxonomy (api, data, updateList) { return { type: actions.FETCH_TAXONOMY, api, payload: data, updateList } }
function _fetchTaxonomies (api, data) { return { type: actions.FETCH_TAXONOMIES, api, payload: data } }
function _fetchTaxonomyItems (api, data) { return { type: actions.FETCH_TAXONOMY_ITEMS, api, payload: data } }
function _removeTaxonomy (api, id) { return { type: actions.REMOVE_TAXONOMY, api, payload: id } }


export default actions