let _consoleOutput = false

/**
 * Habilita el logging por consola
 * 
 * @param {boolean} consoleOutput - habilita (true) / deshabilita (false) el logging
 */
export function setConsoleOutput (consoleOutput) {
  _consoleOutput = !!consoleOutput
}

/**
 * Consulta si el logging está activo
 */
export function isEnabled () {
  return _consoleOutput
}

/**
 * Logea por la consola si el log está activo
 *
 * @param {string} title - título.
 * @param {?function(data: *)} stream - console.info | console.warn | console.err.
 * @param {?Array<*>} args - parámetros para el stream.
 */
export function logger (title, stream, ...args) {
  if (_consoleOutput) {
    forceLogger(title, stream, ...args)
  }
}

/**
 * Logea un mensaje de información.
 * 
 * @param {string} title - título.
 * @param {?Array<*>} args - parámetros para el stream.
 */
export function info (title, ...args) {
  logger(title, console.info, args)
}

/**
 * Logea un mensaje de advertencia.
 * 
 * @param {string} title - título.
 * @param {?Array<*>} args - parámetros para el stream.
 */
export function warning (title, ...args) {
  logger(title, console.warn, args)
}

/**
 * Logea un mensaje de error.
 * 
 * @param {string} title - título.
 * @param {?Array<*>} args - parámetros para el stream.
 */
export function error (title, ...args) {
  logger(title, console.error, args)
}

/**
 * Logea por la consola siempre
 * 
 * @param {*} title 
 * @param {*} stream 
 * @param {*} args 
 */
export function forceLogger (title, stream, ...args) {
  if (console.groupCollapsed) {
    console.groupCollapsed(title)
    if (stream && args) {
      stream(...args)
    }
    console.groupEnd()
  } else {
    stream(`[${title}]:`, args)
  }
}

export default logger
