import { Map } from 'immutable'
import actions from './actions'

const initState = new Map({
  clients: null
});

export default function clientsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_CLIENT:
      return _setClient(state, action.payload, action.updateList)
    case actions.CLEAR_CLIENT:
      return state.set('client', null);
    case actions.FETCH_CLIENTS:
      return state.set('clients', action.payload)
    case actions.FETCH_CLIENT_ITEMS:
      return state.set('clientItems', _toItems(action.payload))
    case actions.REMOVE_CLIENT:
      return _deleteElement(state, action.payload)
    default:
      return state;
  }
}
export function _toItems (clients) {
  let items = []
  if (clients) {
    clients.forEach(client => {
      items.push({ value: client.id, label: client.name })
    })
  }
  return items
}

function _setClient (state, item, updateList) {
  const items = JSON.parse(JSON.stringify(state.get('clients')))
  if (updateList && items) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === item.id)
    if (foundIndex > -1) {
      items[foundIndex] = item
    } else {
      items.push(item)
    }
    return state.set('client', item).set('clients', items)
  } else {
    return state.set('client', item)
  }
}

function _deleteElement (state, id) {
  const items = JSON.parse(JSON.stringify(state.get('clients')))
  if (items && id) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === id)
    if (foundIndex > -1) {
      items.splice(foundIndex, 1)
    }
    return state.set('clients', items)
  }
}

export function getClients (state) {
  return state.Clients.get('clients')
}

export function getClientItems (state) {
  return state.Clients.get('clientItems')
}

export function getClient (state) {
  return state.Clients.get('client')
}

export function getClientFromList (state, clientId) {
  const clients = getClients(state)
  if (clients) {
    var client = clients.find(function(client) { return client.get('id') === clientId })
    return client
  }
  return null
}