import axios from 'axios';
import { get, post, patch } from '../../../api'
import { storeError } from '../general/actions'
import { ApiEndPoint } from '../../../settings/constants'
import { getWidget } from './reducer'
import { getToken } from '../login/reducer'

const actions = {
  FETCH_WIDGETS: "FETCH_WIDGETS",
  FETCH_WIDGET: "FETCH_WIDGET",
  ADD_WIDGET: "ADD_WIDGET",
  EDIT_WIDGET: "EDIT_WIDGET",
  REMOVE_WIDGET: "REMOVE_WIDGET",
  CLEAR_WIDGET: "CLEAR_WIDGET",
  clearWidget: () => {
    return { type: actions.CLEAR_WIDGET };
  },
  fetchWidget: (widgetId, updateList = false) => {
    return function (dispatch, getState) {
      const onSuccess = (data) => { return _fetchWidget(data, updateList) }
      get(`${ApiEndPoint}/widgets/${widgetId}`, null, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  fetchWidgets: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/widgets`, null, _fetchWidgets, storeError, false)(dispatch, getState)
    }
  },
  addWidget: (values) => {
    return function (dispatch, getState) {
      const widget = { ...values, key: _generateKey(), creation_date: Date.now(), type: 'widget' }
      widget.config = JSON.stringify(widget.config)
      const onSuccess = (data) => { return [_fetchWidget(data, true)] }
      post(`${ApiEndPoint}/widgets/new`, widget, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  modifyWidget: (values) => {
    return function (dispatch, getState) {
      if (!values.has_time) {
        if (values.from_date) values.from_date = new Date(values.from_date).setHours(0, 0, 0, 0)
        if (values.to_date) values.to_date = new Date(values.to_date).setHours(0, 0, 0, 0)
      }
      const widget = { ...values, update_date: Date.now() }
      const onSuccess = (data) => { return [_fetchWidget(data, true)] }
      patch(`${ApiEndPoint}/widgets/${widget.id}`, widget, onSuccess, storeError)(dispatch, getState)
    }
  },
  modifyWidgetHeader: (title, content, permalink) => {
    return function (dispatch, getState) {
      let widget = getWidget(getState())
      widget = { ...widget, title, content, permalink }
      actions.modifyWidget(widget)(dispatch, getState)
    }
  },
  addTaxonomyToWidget: (taxonomyId) => {
    return function (dispatch, getState) {
      const widget = getWidget(getState())
      widget.categories ? widget.categories.push(taxonomyId) : widget.categories = [taxonomyId]
      actions.modifyWidget(widget)(dispatch, getState)
    }
  },
  removeTaxonomyFromWidget: (taxonomyId) => {
    return function (dispatch, getState) {
      const widget = getWidget(getState())
      widget.categories = widget.categories.filter(id => id !== taxonomyId)
      actions.modifyWidget(widget)(dispatch, getState)
    }
  },
  modifyWidgetLandmark: (landmarkId) => {
    return function (dispatch, getState) {
      const widget = getWidget(getState())
      widget.landmark_id = landmarkId
      actions.modifyWidget(widget)(dispatch, getState)
    }
  },
  modifyWidgetLocations: (locations) => {
    return function (dispatch, getState) {
      const widget = getWidget(getState())
      widget.locations = locations
      actions.modifyWidget(widget)(dispatch, getState)
    }
  },
  modifyWidgetLanguage: (language, translations) => {
    return function (dispatch, getState) {
      const widget = getWidget(getState())
      widget.multilanguage[language] = { ...translations }
      actions.modifyWidget(widget)(dispatch, getState)
    }
  },
  removeWidget: (widgetId) => {
    return function (dispatch, getState) {
      const access_token = getToken(getState())
      axios.delete(`${ApiEndPoint}/widgets/${widgetId}?access_token=${access_token}`)
        .then(function () {
          dispatch(_removeWidget(widgetId))
        })
    }
  }
}

function _fetchWidget (data, updateList) { return { type: actions.FETCH_WIDGET, updateList, payload: data } }
function _fetchWidgets (data) { return { type: actions.FETCH_WIDGETS, payload: data } }
function _removeWidget (id) { return { type: actions.REMOVE_WIDGET, payload: id } }

function _generateKey () {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 10; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export default actions;