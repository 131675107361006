export const INTEGRATIONS = [
  {
    id: 'teatremao.com',
    name: 'https://teatremao.com',
    imageUrl: 'https://storage.googleapis.com/media.k-lenda.com/integrations/teatremao.jpg',
    integrationUrl: 'https://k-lenda.com/klenda-integrations/ical.php',
    fileUrl: 'https://teatremao.com/event/?ical=1',
    locations: ['5eecde7cd6b4d9769052cfbb'],
    language: 'ca'
  },
  {
    id: 'artxipelag.com',
    name: 'https://artxipelag.com',
    imageUrl: 'https://storage.googleapis.com/media.k-lenda.com/integrations/artxipelag.jpg',
    integrationUrl: 'https://k-lenda.com/klenda-integrations/ical.php',
    fileUrl: 'https://artxipelag.com/event/?ical=1',
    locations: ['5eecde7cd6b4d9769052cfb9', '5eecde7cd6b4d9769052cfbc', '5eecde7cd6b4d9769052cfbb', '5eecde7cd6b4d9769052cfba'],
    language: 'es'
  },
  {
    id: 'agenda.menorca.es',
    name: 'http://agenda.menorca.es',
    imageUrl: 'https://storage.googleapis.com/media.k-lenda.com/integrations/agendamenorca.jpg',
    integrationUrl: 'https://k-lenda.com/klenda-integrations/agendamenorca.php',
    fileUrl: 'http://agenda.menorca.es/Publicacions/llistat.aspx?tipo=01',
    locations: ['5eecde7cd6b4d9769052cfbb'],
    language: 'ca'
  },
  {
    id: 'culturamallorca.cat',
    name: 'http://www.culturamallorca.cat',
    imageUrl: 'https://storage.googleapis.com/media.k-lenda.com/integrations/culturamallorca.jpg',
    integrationUrl: 'https://k-lenda.com/klenda-integrations/culturaMallorca.php',
    fileUrl: 'http://www.culturamallorca.cat/ca/esdeveniments',
    locations: ['5eecde7cd6b4d9769052cfb9'],
    language: 'es'
  }
]
