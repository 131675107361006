import { createAction } from 'redux-actions'

/** Acción de inicio de carga */
export const START_LOADING = 'START_LOADING'
/** Acción de fin de carga */
export const END_LOADING = 'END_LOADING'
/** Acción de almacenado de error */
export const STORE_ERROR = 'STORE_ERROR'

/** Función para controlar el principio de carga */
export const startLoading = () => { return createAction(START_LOADING)(true) }
/** Función para controlar el fin de carga */
export const endLoading = () => { return createAction(END_LOADING)(false) }
/** Función para controlar un error de axios */
export const storeError = () => { return createAction(STORE_ERROR)(false) }