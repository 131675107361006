import { get } from '../../../api'
import { ApiEndPoint } from '../../../settings/constants'
import { storeError } from '../general/actions'

/** Contador de logs */
export const FETCH_COUNTER = 'FETCH_COUNTER'

export function fetchCounter (elementType, elementId, action) {
  return (dispatch, getState) => {
    const url = `${ApiEndPoint}/log/count?where[element_type][like]=${elementType}&where[element_id][like]=${elementId}&where[action][like]=${action}`
    const onSuccess = (data) => { return [_count(data, elementType, elementId, action)] }
    get(url, null, onSuccess, storeError, false)(dispatch, getState)
  }
}

function _count (data, elementType, elementId, action) {
  return { type: FETCH_COUNTER, payload: data.count, elementType, elementId, action }
}