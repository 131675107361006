import axios from 'axios'
import { Map } from 'immutable'
import { startLoading, endLoading } from '../redux/app/general/actions'
import { getToken } from '../redux/app/login/reducer'
import { forceLogger as log } from './logger'

/* Map donde se almacenan los datos cacheados para cada URL / parámetros */
let cache = new Map()

/**
 * Verbo. Tipo de Acción (GET/POST/PUT) sobre la Url
 * @readonly
 * @enum {string}
 * @property {string} GET - Acción HTTP GET
 * @property {string} POST - Acción HTTP POST
 * @property {string} PUT - Acción HTTP PUT
 */
const AXIOS_VERB = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH'
}

/**
 * Función que ejecuta un GET a la url que se le pasa, con los parámetros especificados.
 * Dispone de dos callbacks, uno que se llamará en caso de que la llamada se haya realizado con éxito,
 * y otro en caso de error.
 *
 * El callback de onSuccess puede ser un array de acciones, que se ejecutarán en cadena.
 *
 * Si pasamos el parámetro postOnce a true, el proceso almacenará la respuesta recibida para
 * la URL y parámetros recibidas, y llamará directamente al callback de éxito con los datos cacheados.
 *
 * Además de eso, la función controla el estado del cargando (loading). Marca el inicio de la carga
 * antes de realizar el post, y lo termina después del callback de éxito / error.
 *
 * @param {string} url - URL en la que se quiere hacer el GET.
 * @param {Array<any>} parameters - parámetros a pasar al GET.
 * @param {function (data: any)} onSuccess - callback que se llamará en caso de que haya ido bien.
 * @param {function (error: any)} onError - callback que se llamará en caso de que haya error.
 * @param {boolean} postOnce - indica si queremos cachear la llamada.
 * @param {funciton ()} onFinish - callback que se llamará al terminar
 */

export function get (url, parameters, onSuccess, onError, postOnce, onFinish) {
  return axiosCall(url, AXIOS_VERB.GET, parameters, onSuccess, onError, postOnce, onFinish)
}

/**
 * Función que ejecuta un POST a la url que se le pasa, con los parámetros especificados.
 * Dispone de dos callbacks, uno que se llamará en caso de que la llamada se haya realizado con éxito,
 * y otro en caso de error.
 *
 * El callback de onSuccess puede ser un array de acciones, que se ejecutarán en cadena.
 *
 * Si pasamos el parámetro postOnce a true, el proceso almacenará la respuesta recibida para
 * la URL y parámetros recibidas, y llamará directamente al callback de éxito con los datos cacheados.
 *
 * Además de eso, la función controla el estado del cargando (loading). Marca el inicio de la carga
 * antes de realizar el post, y lo termina después del callback de éxito / error.
 *
 * @param {string} url - URL en la que se quiere hacer el POST.
 * @param {Array<Any>} parameters - parámetros a pasar al POST.
 * @param {function(data: Any)} onSuccess - callback que se llamará en caso de que haya ido bien.
 * @param {function(error: Any)} onError - callback que se llamará en caso de que haya error.
 * @param {boolean} postOnce - indica si queremos cachear la llamada.
 * @param {funciton ()} onFinish - callback que se llamará al terminar
 */
export function post (url, parameters, onSuccess, onError, postOnce, onFinish) {
  console.log(parameters)
  return axiosCall(url, AXIOS_VERB.POST, parameters, onSuccess, onError, postOnce, onFinish)
}

/**
 * Función que ejecuta un PUT a la url que se le pasa, con los parámetros especificados.
 * Dispone de dos callbacks, uno que se llamará en caso de que la llamada se haya realizado con éxito,
 * y otro en caso de error.
 *
 * El callback de onSuccess puede ser un array de acciones, que se ejecutarán en cadena.
 *
 * Si pasamos el parámetro postOnce a true, el proceso almacenará la respuesta recibida para
 * la URL y parámetros recibidas, y llamará directamente al callback de éxito con los datos cacheados.
 *
 * Además de eso, la función controla el estado del cargando (loading). Marca el inicio de la carga
 * antes de realizar el put, y lo termina después del callback de éxito / error.
 *
 * @param {string} url - URL en la que se quiere hacer el PUT.
 * @param {Array<Any>} parameters - parámetros a pasar al PUT.
 * @param {function(data: Any)} onSuccess - callback que se llamará en caso de que haya ido bien.
 * @param {function(error: Any)} onError - callback que se llamará en caso de que haya error.
 */
export function put (url, parameters, onSuccess, onError) {
  return axiosCall(url, AXIOS_VERB.PUT, parameters, onSuccess, onError)
}

/**
 * Función que ejecuta un PATCH a la url que se le pasa, con los parámetros especificados.
 * Dispone de dos callbacks, uno que se llamará en caso de que la llamada se haya realizado con éxito,
 * y otro en caso de error.
 *
 * El callback de onSuccess puede ser un array de acciones, que se ejecutarán en cadena.
 *
 * Si pasamos el parámetro postOnce a true, el proceso almacenará la respuesta recibida para
 * la URL y parámetros recibidas, y llamará directamente al callback de éxito con los datos cacheados.
 *
 * Además de eso, la función controla el estado del cargando (loading). Marca el inicio de la carga
 * antes de realizar el patch, y lo termina después del callback de éxito / error.
 *
 * @param {string} url - URL en la que se quiere hacer el PATCH.
 * @param {Array<Any>} parameters - parámetros a pasar al PATCH.
 * @param {function(data: Any)} onSuccess - callback que se llamará en caso de que haya ido bien.
 * @param {function(error: Any)} onError - callback que se llamará en caso de que haya error.
 */
export function patch (url, parameters, onSuccess, onError) {
  return axiosCall(url, AXIOS_VERB.PATCH, parameters, onSuccess, onError)
}

export function clearCache(url, parameters) {
  let resourceUrl = _getUrl(url, AXIOS_VERB.GET, parameters)
  cache.delete(resourceUrl)
}

/**
 * Función que ejecuta un GET/POST a la url que se le pasa, con los parámetros especificados.
 * Dispone de dos callbacks, uno que se llamará en caso de que la llamada se haya realizado con éxito,
 * y otro en caso de error.
 *
 * El callback de onSuccess puede ser un array de acciones, que se ejecutarán en cadena.
 *
 * Si pasamos el parámetro postOnce a true, el proceso almacenará la respuesta recibida para
 * la URL y parámetros recibidas, y llamará directamente al callback de éxito con los datos cacheados.
 *
 * Además de eso, la función controla el estado del cargando (loading). Marca el inicio de la carga
 * antes de realizar el post, y lo termina después del callback de éxito / error.
 *
 * @param {string} url - URL.
 * @param {AXIOS_VERB} verb - Tipo de acción (GET/POST)
 * @param {Array<any>} parameters - parámetros a pasar.
 * @param {function(data: any)} onSuccess - callback que se llamará en caso de que haya ido bien.
 * @param {function(error: any)} onError - callback que se llamará en caso de que haya error.
 * @param {boolean} [callOne = false] - indica si queremos cachear la llamada.
 * @param {function ()} onFinish - callback que se llamará al terminar
 */
function axiosCall (url, verb, parameters, onSuccess, onError, callOne = false, onFinish) {
  return (dispatch, getState) => {
    const access_token = getToken(getState())

    let authURL = url;
    if (!url.includes("login")) { //Si no es login, concatenamos el token a la petición
      authURL = (url.includes('?'))
      ? `${url}&access_token=${access_token}`
      : `${url}/?access_token=${access_token}`
    }

    let resourceUrl = _getUrl(url, verb, parameters)

    let cachedResponse = null
    /* Comprobamos si tenemos la respuesta cacheada */
    if (callOne) {
      cachedResponse = cache.get(resourceUrl)
    }

    if (cachedResponse) {
      /* si está cacheada, llamamos directamente al onSuccess con los datos cacheados */
      _callOnSuccess(dispatch, getState, onSuccess, cachedResponse)
    } else {
      /* Únicamente mostraremos el loading en caso de que la transacción tarde más de 200 milisegundos */
      let timeoutId = setTimeout(() => { dispatch(startLoading()) }, 3000)

      let axiosAction
      let params

      switch (verb) {
        case AXIOS_VERB.GET: axiosAction = axios.get; params = { params: parameters }; break;
        case AXIOS_VERB.POST: axiosAction = axios.post; params = parameters; break;
        case AXIOS_VERB.PUT: axiosAction = axios.put; params = parameters; break;
        case AXIOS_VERB.PATCH: axiosAction = axios.patch; params = parameters; break;
      }

      axiosAction(authURL, params)
        .then(response => {
          if (callOne) {
            /* si hay que cachear la petición, almacenamos el resultado en el map */
            cache = cache.set(resourceUrl, response)
          }
          _callOnSuccess(dispatch, getState, onSuccess, response)
          clearTimeout(timeoutId)
          _callOnFinish(dispatch, onFinish)
          dispatch(endLoading())
        })
        .catch(error => {
          log('AXIOS ERROR: ', console.error, error, { authURL, params })
          if (onError) {
            _callOnError(dispatch, onError, error, authURL)
          }
          clearTimeout(timeoutId)
          _callOnFinish(dispatch, onFinish)
          dispatch(endLoading())
        })
    }
  }
}

/**
 * Función que ejecuta la acción o acciones del callback onSuccess.
 *
 * @param {*} dispatch - función que despacha la acción
 * @param {*} getState - función de acceso al estado
 * @param {function(data: Any} onSuccess - función callback o array
 * de funciones para cuando la llamada va bien.
 *  Las funciones de callback amitidas son del tipo:
 *    (dispatch, getState) => {}
 *  o bien (data) => {}
 * @param {*} response - respuesta de axios
 * @param {*} i - ¿número de llamadas?
 */
function _callOnSuccess (dispatch, getState, onSuccess, response, i = 1) {
  if (onSuccess) {
    if (onSuccess instanceof Array) {
      onSuccess.forEach(item => {
        _callOnSuccess(dispatch, getState, item, response, i + 1)
      })
    } else if (onSuccess instanceof Function) {
      if (onSuccess.length === 2) {
        _callOnSuccess(dispatch, getState, onSuccess(dispatch, getState), response, i + 1)
      } else {
        _callOnSuccess(dispatch, getState, onSuccess(response.data), response, i + 1)
      }
    } else if (onSuccess.type) {
      dispatch(onSuccess)
    } else {
      log('K-LENDA WARNING: ', console.warn, 'Unknown onSuccess type', {onSuccess})
    }
  }
}

/**
 * Función que ejecuta la acción o acciones del callback onSuccess.
 *
 * @param {*} dispatch - función que ejecutará
 * @param {function(data: Any} onError - función callback en caso de error
 * @param {*} error - mensaje de error
 * @param {*} url - url que ha dado el error
 */
function _callOnError (dispatch, onError, error, url) {
  let errorActions = onError(error, url)
  if (errorActions instanceof Array) {
    errorActions.map((action) => {
      if (action) {
        dispatch(action)
      }
    })
  } else {
    dispatch(errorActions)
  }
}

/**
 * Función que ejecuta la acción o acciones del callback onSuccess.
 *
 * @param {*} dispatch - función que ejecutará
 * @param {function(data: Any} onFinish - función callback
 */
function _callOnFinish (dispatch, onFinish) {
  if (onFinish) {
    let finishActions = onFinish()
    if (finishActions instanceof Array) {
      finishActions.map((action) => {
        if (action) {
          dispatch(action)
        }
      })
    } else {
      dispatch(finishActions)
    }
  }
}

/**
 * Genera un string concatenando la URL y los parámetros especificados.
 *
 * @param {string} url - URL en la que se quiere hacer el POST.
 * @param {AXIOS_VERB} verb - Tipo de acción
 * @param {Array<any>} parameters - parámetros a pasar al POST.
 */
function _getUrl (url, verb, parameters) {
  if (parameters) {
    return `${url}#${verb}#${JSON.stringify(parameters)}`
  }
  return `${url}#${verb}`
}
