import { Map } from 'immutable'
import actions from "./actions"
import { getLastModified } from '../utils'

const initState = new Map({
  routes: null
});

export default function routesReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_ROUTE:
    return _setRoute(state, action.payload, action.updateList)
    case actions.CLEAR_ROUTE:
      return state.set('route', null)
    case actions.FETCH_ROUTES:
      return state.set('routes', getLastModified(action.payload))
    case actions.FETCH_ROUTE_ITEMS:
      return state.set('routeItems', _toItems(action.payload))
    case actions.REMOVE_ROUTE:
      return _deleteElement(state, action.payload)
    default:
      return state;
  }
}

function _setRoute (state, item, updateList) {
  const items = JSON.parse(JSON.stringify(state.get('routes')))
  if (updateList && items) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === item.id)
    if (foundIndex > -1) {
      items[foundIndex] = item
    } else {
      items.push(item)
    }
    return state.set('route', item).set('routes', getLastModified(items))
  } else {
    return state.set('route', item)
  }
}

function _deleteElement (state, id) {
  const items = JSON.parse(JSON.stringify(state.get('routes')))
  if (items && id) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === id)
    if (foundIndex > -1) {
      items.splice(foundIndex, 1)
    }
    return state.set('routes', getLastModified(items))
  }
}

export function _toItems (routes) {
  let items = []
  if (routes) {
    routes.forEach(route => {
      items.push({ value: route.id, label: route.title })
    })
  }
  return items
}

export function getRouteItems (state) {
  return state.Routes.get('routeItems')
}

export function getRoutes (state) {
  return state.Routes.get('routes')
}

export function getRoute(state) {
  return state.Routes.get('route')
}

export function getRouteFromList (state, routeId) {
  const routes = getRoutes(state)
  if (routes) {
    var map = routes
    var route = map.find(function(route) { return route.get('id') === routeId });
    return route;
  }
  return null
}