import { post } from '../../../api'
import { ApiEndPoint } from '../../../settings/constants'
import { storeError } from '../general/actions'

/** Acción de subir imagen a S3 */
export const UPLOAD = 'UPLOAD'
export const CLEAR = 'CLEAR'
export const SET_COPIED_IMAGE_URL = 'SET_COPIED_IMAGE_URL'

export const uploadImage = (elementId, image) => {
  return function (dispatch, getState) {
    /* primero quitamos la última imagen guardada en el estado */
    dispatch(clearImage())
    const formData = new FormData()
    formData.append('file', image)
    //console.log('ELEMENT_ID', elementId)
    //console.log('IMAGE', image)

    /* luego hacemos el upload, así si falla no habrá imagen en el estado */
    post(`${ApiEndPoint}/images/${elementId}/shapeAndUpload`, formData, _uploadImage, storeError, false)(dispatch, getState)
  }
}

export const setCopiedImageUrl = (url) => {
  return function (dispatch, getState) {
    dispatch(_setCopiedImageUrl(url))
  }
}

export function clearImage () { return { type: CLEAR } }

function _uploadImage (data) { return { type: UPLOAD, payload: data.result } }
function _setCopiedImageUrl (url) { return {type: SET_COPIED_IMAGE_URL, payload: url}}
