import axios from 'axios';
import { getToken, getUserId } from '../login/reducer'
import { getUserFromList } from '../users/reducer'
import { ApiEndPoint, ADMIN } from '../../../settings/constants'
import { INTEGRATIONS } from '../../../integrations'

const actions = {
  FETCH_DASHBOARD: "FETCH_DASHBOARD",
  fetchDashboard: () => {
    return function (dispatch, getState) {
      const access_token = getToken(getState())
      const params = `?access_token=${access_token}`
      let promises = [];
      const user = getUserFromList(getState(), getUserId(getState())) 

      promises.push(axios.get(`${ApiEndPoint}/events/count/${params}`));
      promises.push(axios.get(`${ApiEndPoint}/landmarks/count/${params}`));
      promises.push(axios.get(`${ApiEndPoint}/routes/count/${params}`));
      promises.push(axios.get(`${ApiEndPoint}/tips/count/${params}`));
      promises.push(axios.get(`${ApiEndPoint}/ads/count/${params}`));
      promises.push(axios.get(`${ApiEndPoint}/todos/count/${params}`));
      promises.push(axios.get(`${ApiEndPoint}/locations/count/${params}`));

      if (user && user.role === ADMIN) {
        promises.push(axios.get(`${ApiEndPoint}/widgets/count/${params}`));
        promises.push(axios.get(`${ApiEndPoint}/admins/count/${params}`));
        promises.push(axios.get(`${ApiEndPoint}/clients/count/${params}`));
      }

      var dashboardData = {};
      axios.all(promises)
        .then(axios.spread((...args) => {
          dashboardData['eventsCount'] = args[0].data.count;
          dashboardData['landmarksCount'] = args[1].data.count;
          dashboardData['routesCount'] = args[2].data.count;
          dashboardData['tipsCount'] = args[3].data.count;
          dashboardData['adsCount'] = args[4].data.count;
          dashboardData['todosCount'] = args[5].data.count;
          dashboardData['locationsCount'] = args[6].data.count;

          if (user && user.role === ADMIN) {
            dashboardData['widgetsCount'] = args[7].data.count;
            dashboardData['usersCount'] = args[8].data.count;
            dashboardData['clientsCount'] = args[9].data.count;
            dashboardData['integrationsCount'] = INTEGRATIONS.length;
          }
        }))
        .then(function () {
          dispatch(
            {
              type: actions.FETCH_DASHBOARD,
              payload: dashboardData
            }
          )
        });



    }
  },
}

export default actions;