import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import persistState from 'redux-localstorage'
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { reducer as reduxFormReducer } from 'redux-form'
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { Map, fromJS } from 'immutable'

import reducers from './reducers';
import rootSaga from './sagas';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

// persistState configuration for immutable.js
const config = {
  serialize: (state) => {
    return JSON.stringify((state.Login || new Map()))
  },
  deserialize: (serializedData) => {
    return { Login: fromJS(JSON.parse(serializedData || new Map())) }
  },
  merge: (initialState, persistedState) => {
    if (initialState) {
      initialState.Login.mergeDeep(fromJS(persistedState.Login))
    } else {
      initialState = persistedState
    }
    return initialState || new Map()
  }
}


const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        
      })
    : compose;

const store = createStore(
  combineReducers({
    ...reducers,
    router: connectRouter(history),
    form: reduxFormReducer
  }),
  composeEnhancers(applyMiddleware(...middlewares), persistState('Login', config))
);
sagaMiddleware.run(rootSaga);
export { store, history };
