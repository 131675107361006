import axios from 'axios';
import { get, post, put } from '../../../api'
import { storeError } from '../general/actions'
import { ApiEndPoint } from '../../../settings/constants'
import { getTodo } from './reducer'
import { getToken } from '../login/reducer'

const actions = {
  FETCH_TODOS: "FETCH_TODOS",
  FETCH_TODO: "FETCH_TODO",
  FETCH_TODO_ITEMS: "FETCH_TODO_ITEMS",
  ADD_TODO: "ADD_TODO",
  EDIT_TODO: "EDIT_TODO",
  REMOVE_TODO: "REMOVE_TODO",
  CLEAR_TODO: "CLEAR_TODO",
  clearTodo: () => {
    return { type: actions.CLEAR_TODO };
  },
  fetchTodo: (todoId, updateList = false) => {
    return function (dispatch, getState) {
      const onSuccess = (data) => { return _fetchTodo(data, updateList) }
      get(`${ApiEndPoint}/todos/${todoId}`, null, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  fetchTodos: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/todos`, null, _fetchTodos, storeError, false)(dispatch, getState)
    }
  },
  fetchTodoItems: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/todos`, null, _fetchTodoItems, storeError, false)(dispatch, getState)
    }
  },
  addTodo: (values) => {
    return function (dispatch, getState) {
      const todo = { ...values, creation_date: Date.now(), type: 'todo' }
      const onSuccess = (data) => { return [_fetchTodo(data, true)] }
      post(`${ApiEndPoint}/todos`, todo, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  modifyTodo: (values) => {
    return function (dispatch, getState) {
      if (!values.has_time) {
        if (values.from_date) values.from_date = new Date(values.from_date).setHours(0, 0, 0, 0)
        if (values.to_date) values.to_date = new Date(values.to_date).setHours(0, 0, 0, 0)
      }
      const todo = { ...values, update_date: Date.now() }
      const onSuccess = (data) => { return [_fetchTodo(data, true)] }
      put(`${ApiEndPoint}/todos/${todo.id}`, todo, onSuccess, storeError)(dispatch, getState)
    }
  },
  modifyTodoHeader: (title, content, permalink) => {
    return function (dispatch, getState) {
      let todo = getTodo(getState())
      todo = { ...todo, title, content, permalink }
      actions.modifyTodo(todo)(dispatch, getState)
    }
  },
  addTaxonomyToTodo: (taxonomyId) => {
    return function (dispatch, getState) {
      const todo = getTodo(getState())
      todo.categories ? todo.categories.push(taxonomyId) : todo.categories = [taxonomyId]
      actions.modifyTodo(todo)(dispatch, getState)
    }
  },
  removeTaxonomyFromTodo: (taxonomyId) => {
    return function (dispatch, getState) {
      const todo = getTodo(getState())
      todo.categories = todo.categories.filter(id => id !== taxonomyId)
      actions.modifyTodo(todo)(dispatch, getState)
    }
  },
  modifyTodoLandmark: (landmarkId) => {
    return function (dispatch, getState) {
      const todo = getTodo(getState())
      todo.landmark_id = landmarkId
      actions.modifyTodo(todo)(dispatch, getState)
    }
  },
  modifyTodoLocations: (locations) => {
    return function (dispatch, getState) {
      const todo = getTodo(getState())
      todo.locations = locations
      actions.modifyTodo(todo)(dispatch, getState)
    }
  },
  modifyTodoLanguage: (language, translations) => {
    return function (dispatch, getState) {
      const todo = getTodo(getState())
      todo.multilanguage[language] = { ...translations }
      actions.modifyTodo(todo)(dispatch, getState)
    }
  },
  removeTodo: (todoId) => {
    return function (dispatch, getState) {
      const access_token = getToken(getState())
      axios.delete(`${ApiEndPoint}/todos/${todoId}?access_token=${access_token}`)
        .then(function () {
          dispatch(_removeTodo(todoId))
        })
    }
  },
}

function _fetchTodo (data, updateList) { return { type: actions.FETCH_TODO, updateList, payload: data } }
function _fetchTodos (data) { return { type: actions.FETCH_TODOS, payload: data } }
function _fetchTodoItems (data) { return { type: actions.FETCH_TODO_ITEMS, payload: data } }
function _removeTodo (id) { return { type: actions.REMOVE_TODO, payload: id } }

export default actions;