import axios from 'axios'
import { get, post, put } from '../../../api'
import { storeError } from '../general/actions'
import { ApiEndPoint } from '../../../settings/constants'
import { DEFAULT_LANGUAGE } from '../../../settings/constants'
import { getAd } from './reducer'
import { getImage } from '../media/reducer'
import { getToken } from '../login/reducer'

const actions = {
  FETCH_ADS: "FETCH_ADS",
  FETCH_AD: "FETCH_AD",
  ADD_AD: "ADD_AD",
  EDIT_AD: "EDIT_AD",
  REMOVE_AD: "REMOVE_AD",
  CLEAR_AD: "CLEAR_AD",
  clearAd: () => {
    return { type: actions.CLEAR_AD };
  },
  fetchAd: (adId, updateList = false) => {
    return function (dispatch, getState) {
      const onSuccess = (data) => { return _fetchAd(data, updateList) }
      get(`${ApiEndPoint}/ads/${adId}`, null, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  fetchAds: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/ads`, null, _fetchAds, storeError, false)(dispatch, getState)
    }
  },
  addAd: (values) => {
    return function (dispatch, getState) {
      const ad = { ...values, language: DEFAULT_LANGUAGE, creation_date: Date.now(), type: 'ad' }
      const onSuccess = (data) => { return [_fetchAd(data, true)] }
      post(`${ApiEndPoint}/ads`, ad, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  modifyAd: (values) => {
    return function (dispatch, getState) {
      if (values.start_date) values.start_date = new Date(values.start_date).setHours(0, 0, 0, 0)
      if (values.end_date) values.end_date = new Date(values.end_date).setHours(0, 0, 0, 0)
      const ad = { ...values, update_date: Date.now() }
      const onSuccess = (data) => { return [_fetchAd(data, true)] }
      put(`${ApiEndPoint}/ads/${ad.id}`, ad, onSuccess, storeError)(dispatch, getState)
    }
  },
  modifyAdDefaultLanguage: (language) => {
    return function (dispatch, getState) {
      let ad = getAd(getState())
      ad.language = language
      if (ad.multilanguage) {
        delete (ad.multilanguage[language])
      }
      actions.modifyAd(ad)(dispatch, getState)
    }
  },
  modifyAdHeader: (title, content, permalink) => {
    return function (dispatch, getState) {
      let ad = getAd(getState())
      ad = { ...ad, title, content, permalink }
      actions.modifyAd(ad)(dispatch, getState)
    }
  },
  modifyAdImage: () => {
    return function (dispatch, getState) {
      let ad = getAd(getState())
      let images = getImage(getState())
      ad = { ...ad, images }
      actions.modifyAd(ad)(dispatch, getState)
    }
  },
  addTaxonomyToAd: (taxonomyId) => {
    return function (dispatch, getState) {
      const ad = getAd(getState())
      ad.categories ? ad.categories.push(taxonomyId) : ad.categories = [taxonomyId]
      actions.modifyAd(...ad)(dispatch)
    }
  },
  removeTaxonomyFromAd: (taxonomyId) => {
    return function (dispatch, getState) {
      const ad = getAd(getState())
      ad.categories = ad.categories.filter(id => id !== taxonomyId)
      actions.modifyAd(...ad)(dispatch)
    }
  },
  modifyAdLocations: (locations) => {
    return function (dispatch, getState) {
      const ad = getAd(getState())
      ad.locations = locations
      actions.modifyAd(...ad)(dispatch)
    }
  },
  modifyAdLanguage: (language, translations) => {
    return function (dispatch, getState) {
      const ad = getAd(getState())
      if (!ad.multilanguage) ad.multilanguage = {}
      ad.multilanguage[language] = { ...translations }
      actions.modifyAd(ad)(dispatch, getState)
    }
  },
  removeAd: (adId) => {
    return function (dispatch, getState) {
      const access_token = getToken(getState())
      axios.delete(`${ApiEndPoint}/ads/${adId}?access_token=${access_token}`)
        .then(function () {
          dispatch(_removeAd(adId))
        })
    }
  }
}

function _fetchAd (data, updateList) { return { type: actions.FETCH_AD, updateList, payload: data } }
function _fetchAds (data) { return { type: actions.FETCH_ADS, payload: data } }
function _removeAd (id) { return { type: actions.REMOVE_AD, payload: id } }

export default actions