/**
 * Función para evitar código duplicado verificando si un elemento es null antes de hacer el toJS()
 */
export const safeToJS = (element) => {
  return (element) ? element.toJS() : element
}

/**
 * Ordena los elementos que se le pasan por última fecha de modificación o creación si no se ha modificado.
 *
 * @param {array} elements - array de elementos a ordenar
 */
export const getLastModified = (elements) => {
  if (elements) {
    elements.sort(function (a, b) {
      if (a.update_date) {
        if (b.update_date) {
          return checkDateOrId(b.update_date, a.update_date, a.id, b.id)
        } else {
          return checkDateOrId(b.creation_date, a.update_date, a.id, b.id)
        }
      }
      return checkDateOrId(b.creation_date, a.creation_date, a.id, b.id)
    })
  }
  return elements
}

const checkDateOrId = (leftDate, rightDate, leftId, rightId) => {
  const lDate = new Date(leftDate)
  const rDate = new Date(rightDate)
  if (lDate.getTime() === rDate.getTime()) {
    return (leftId > rightId) ? 1 : -1
  } else {
    return lDate - rDate
  }
}