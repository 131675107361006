import axios from 'axios';
import { get, post, put } from '../../../api'
import { storeError } from '../general/actions'
import { ApiEndPoint } from '../../../settings/constants'
import { getClient } from './reducer'
import { getToken } from '../login/reducer'

const actions = {
  FETCH_CLIENTS: "FETCH_CLIENTS",
  FETCH_CLIENT: "FETCH_CLIENT",
  FETCH_CLIENT_ITEMS: "FETCH_CLIENT_ITEMS",
  ADD_CLIENT: "ADD_CLIENT",
  EDIT_CLIENT: "EDIT_CLIENT",
  REMOVE_CLIENT: "REMOVE_CLIENT",
  CLEAR_CLIENT: "CLEAR_CLIENT",
  clearClient: () => {
    return { type: actions.CLEAR_CLIENT };
  },
  fetchClient: (clientId, updateList = false) => {
    return function (dispatch, getState) {
      const onSuccess = (data) => { return _fetchClient(data, updateList) }
      get(`${ApiEndPoint}/clients/${clientId}`, null, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  fetchClients: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/clients`, null, _fetchClients, storeError, false)(dispatch, getState)
    }
  },
  fetchClientItems: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/clients`, null, _fetchClientItems, storeError, false)(dispatch, getState)
    }
  },
  addClient: (values) => {
    return function (dispatch, getState) {
      const client = { ...values, creation_date: Date.now(), type: 'client' }
      const onSuccess = (data) => { return [_fetchClient(data, true)] }
      post(`${ApiEndPoint}/clients`, client, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  modifyClient: (values) => {
    return function (dispatch, getState) {
      if (!values.has_time) {
        if (values.from_date) values.from_date = new Date(values.from_date).setHours(0, 0, 0, 0)
        if (values.to_date) values.to_date = new Date(values.to_date).setHours(0, 0, 0, 0)
      }
      const client = { ...values, update_date: Date.now() }
      const onSuccess = (data) => { return [_fetchClient(data, true)] }
      put(`${ApiEndPoint}/clients/${client.id}`, client, onSuccess, storeError)(dispatch, getState)
    }
  },
  modifyClientHeader: (title, content, permalink) => {
    return function (dispatch, getState) {
      let client = getClient(getState())
      client = { ...client, title, content, permalink }
      actions.modifyClient(client)(dispatch, getState)
    }
  },
  addTaxonomyToClient: (taxonomyId) => {
    return function (dispatch, getState) {
      const client = getClient(getState())
      client.categories ? client.categories.push(taxonomyId) : client.categories = [taxonomyId]
      actions.modifyClient(client)(dispatch, getState)
    }
  },
  removeTaxonomyFromClient: (taxonomyId) => {
    return function (dispatch, getState) {
      const client = getClient(getState())
      client.categories = client.categories.filter(id => id !== taxonomyId)
      actions.modifyClient(client)(dispatch, getState)
    }
  },
  modifyClientLandmark: (landmarkId) => {
    return function (dispatch, getState) {
      const client = getClient(getState())
      client.landmark_id = landmarkId
      actions.modifyClient(client)(dispatch, getState)
    }
  },
  modifyClientLocations: (locations) => {
    return function (dispatch, getState) {
      const client = getClient(getState())
      client.locations = locations
      actions.modifyClient(client)(dispatch, getState)
    }
  },
  modifyClientLanguage: (language, translations) => {
    return function (dispatch, getState) {
      const client = getClient(getState())
      client.multilanguage[language] = { ...translations }
      actions.modifyClient(client)(dispatch, getState)
    }
  },
  removeClient: (clientId) => {
    return function (dispatch, getState) {
      const access_token = getToken(getState())
      axios.delete(`${ApiEndPoint}/clients/${clientId}?access_token=${access_token}`)
        .then(function () {
          dispatch(_removeClient(clientId))
        })
    }
  }
}

function _fetchClient (data, updateList) { return { type: actions.FETCH_CLIENT, updateList, payload: data } }
function _removeClient (id) { return { type: actions.REMOVE_CLIENT, payload: id } }
function _fetchClients (data) { return { type: actions.FETCH_CLIENTS, payload: data } }
function _fetchClientItems (data) { return { type: actions.FETCH_CLIENT_ITEMS, payload: data } }

export default actions;