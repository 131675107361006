import { Map } from 'immutable';
import actions from "./actions";
import { getLastModified } from '../utils'

const initState = new Map({
  ads: null
});

export default function adsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_AD:
    return _setAd(state, action.payload, action.updateList)
    case actions.CLEAR_AD:
      return state.set('ad', null);
    case actions.FETCH_ADS:
      return state.set('ads', getLastModified(action.payload))
    case actions.REMOVE_AD:
      return _deleteElement(state, action.payload)
    default:
      return state;
  }
}

function _setAd (state, item, updateList) {
  const items = JSON.parse(JSON.stringify(state.get('ads')))
  if (updateList && items) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === item.id)
    if (foundIndex > -1) {
      items[foundIndex] = item
    } else {
      items.push(item)
    }
    return state.set('ad', item).set('ads', getLastModified(items))
  } else {
    return state.set('ad', item)
  }
}

function _deleteElement (state, id) {
  const items = JSON.parse(JSON.stringify(state.get('ads')))
  if (items && id) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === id)
    if (foundIndex > -1) {
      items.splice(foundIndex, 1)
    }
    return state.set('ads', getLastModified(items))
  }
}

export function getAds (state) {
  return state.Ads.get('ads')
}

export function getAd (state) {
  return state.Ads.get('ad')
}

export function getAdFromList (state, adId) {
  const ads = getAds(state)
  if (ads) {
    var ad = ads.find(function(ad) { return ad.get('id') === adId })
    return ad
  }
  return null
}