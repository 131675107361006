
import Eslang from './entries/es_ES';


const AppLocale = {

  es: Eslang,

};

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  // require(AppLocale.en.data); // Add locale data for de
  // require(AppLocale.zh.data); // Add locale data for de
  // require(AppLocale.sa.data); // Add locale data for de
  // require(AppLocale.it.data); // Add locale data for de
  // require(AppLocale.es.data); // Add locale data for de
  // require(AppLocale.fr.data); // Add locale data for de
}

export default AppLocale;
