import { post } from '../../../api'
import { ApiEndPoint } from '../../../settings/constants'
import { storeError } from '../general/actions'
import actions from '../users/actions'

/** Acción de login */
export const LOGIN = 'LOGIN'
/** Acción de logout */
export const LOGOUT = 'LOGOUT'

export function login (email, password) {
  return (dispatch, getState) => {
    const parameters = {
      email, password
    }
    const onSuccess = (data) => { return [ _login(data), actions.fetchUser(data.userId)]}
    post(`${ApiEndPoint}/admins/login`, parameters, onSuccess, storeError, false)(dispatch, getState)
  }
}

export function logout () {
  return (dispatch, getState) => {
    const onSuccess = () => {return [_logout(), actions.clearUser()]}
    post(`${ApiEndPoint}/admins/logout`, null, onSuccess, storeError, false)(dispatch, getState)
  }
}


function _login (data) { return { type: LOGIN, payload: data } }
function _logout () { return { type: LOGOUT } }