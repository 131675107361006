import { Map } from 'immutable'
import actions from './actions'

const initState = new Map({
  todos: null
});

export default function todosReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_TODO:
      return _setTodo(state, action.payload, action.updateList)
    case actions.CLEAR_TODO:
      return state.set('todo', null);
    case actions.FETCH_TODOS:
      return state.set('todos', action.payload)
    case actions.FETCH_TODO_ITEMS:
      return state.set('todoItems', _toItems(action.payload))
    case actions.REMOVE_TODO:
      return _deleteElement(state, action.payload)
    default:
      return state;
  }
}
export function _toItems (todos) {
  let items = []
  if (todos) {
    todos.forEach(todo => {
      items.push({ value: todo.id, label: todo.name })
    })
  }
  return items
}

function _setTodo (state, item, updateList) {
  const items = JSON.parse(JSON.stringify(state.get('todos')))
  if (updateList && items) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === item.id)
    if (foundIndex > -1) {
      items[foundIndex] = item
    } else {
      items.push(item)
    }
    return state.set('todo', item).set('todos', items)
  } else {
    return state.set('todo', item)
  }
}

function _deleteElement (state, id) {
  const items = JSON.parse(JSON.stringify(state.get('todos')))
  if (items && id) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === id)
    if (foundIndex > -1) {
      items.splice(foundIndex, 1)
    }
    return state.set('todos', items)
  }
}

export function getTodos (state) {
  return state.Todos.get('todos')
}

export function getTodoItems (state) {
  return state.Todos.get('todoItems')
}

export function getTodo (state) {
  return state.Todos.get('todo')
}

export function getTodoFromList (state, todoId) {
  const todos = getTodos(state)
  if (todos) {
    var map = todos
    var todo = map.find(function(todo) { return todo.get('id') === todoId })
    return todo
  }
  return null
}