import Auth from './auth/reducer'
import App from './app/reducer'
import Ads from './app/ads/reducer'
import Clients from './app/clients/reducer'
import Dashboard from './app/dashboard/reducer'
import Events from './app/events/reducer'
import General from './app/general/reducer'
import Landmarks from './app/landmarks/reducer'
import Log from './app/log/reducer'
import Login from './app/login/reducer'
import Media from './app/media/reducer'
import Routes from './app/routes/reducer'
import Tips from './app/tips/reducer'
import Taxonomies from './app/taxonomies/reducer'
import Todos from './app/todos/reducer'
import Users from './app/users/reducer'
import Widgets from './app/widgets/reducer'
import ThemeSwitcher from './themeSwitcher/reducer'
import LanguageSwitcher from './languageSwitcher/reducer'

export default {
  Auth,
  App,
  Ads,
  Clients,
  Dashboard,
  Events,
  General,
  Landmarks,
  Log,
  Login,
  Media,
  Routes,
  Tips,
  Taxonomies,
  Todos,
  Users,
  Widgets,
  ThemeSwitcher,
  LanguageSwitcher
};
