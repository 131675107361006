import { Map } from 'immutable'
import { LOGIN, LOGOUT } from './actions'
import { TOKEN_TTL_DAYS } from '../../../settings/constants'

/**
 * Reducer para el almacenamiento de datos generales de la aplicación.
 *
 * @param {Object} state - estado de redux
 * @param {Object} action - acción a ejecutar
 */
export default function (state = new Map(), action) {
  switch (action.type) {
    case LOGIN:
      //console.log('SETTING LOGIN', action.payload)
      return state
        .set('token', action.payload.id)
        .set('created', action.payload.created)
        .set('ttl', action.payload.ttl)
        .set('userId', action.payload.userId)
    case LOGOUT:
      return state
        .set('token', null)
        .set('created', null)
        .set('ttl', null)
        .set('userId', null)
  }

  return state
}

/**
 * Devuelve el id de usuario que ha hecho login
 *
 * @param {Object} state - Estado general
 * @return {string} - id de usuario
 */
export function getUserId (state) {
  if (state.Login) {
    return state.Login.get('userId')
  }
}

/**
 * Devuelve el token del login
 *
 * @param {Object} state - Estado general
 * @return {string} - Token
 */
export function getToken (state) {
  if (state.Login) {
    const tokenCreateDate = state.Login.get('created')
    if (tokenCreateDate) {
      const now = new Date()
      const tokenTTL = new Date(tokenCreateDate)
      tokenTTL.setDate(tokenTTL.getDate() + TOKEN_TTL_DAYS)
      if (tokenTTL > now) {
        return state.Login.get('token')
      }
    }
  }
  return null
}