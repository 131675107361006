import React, { Component } from 'react';
import { connect } from 'react-redux';

import Icon from '../../components/uielements/icon';
import appActions from '../../redux/app/actions';
import { getUserFromList } from '../../redux/app/users/reducer'
import { getUserId } from '../../redux/app/login/reducer'

import themeActions from '../../redux/themeSwitcher/actions';
import { AppHolder, Toolbar, IconButtons, TopbarComponents } from './style';
import TopbarUser from './topbarUser';
import { Link } from 'react-router-dom';
import Logo from '../../images/klenda/logo-klenda.png';

const { toggleCollapsed } = appActions;
const { switchActivation } = themeActions;

const renderUser = (user, propsTopbar) => {
  if (user) {
    return (
      <TopbarComponents>
        <ul className="topbarItems">
          <li className="helloUser">Hola, {user.name}</li>
          <li className="topbarUser">
            <TopbarUser avatar_url={user.avatar_url} {...propsTopbar} />
          </li>
        </ul>
      </TopbarComponents>
    )
  }
}
class Topbar extends Component {
  render() {
    const {
      toggleCollapsed,
      locale,
      url,
      user,
      customizedTheme
    } = this.props;
    const propsTopbar = { locale, url };
    //console.log("USER", user)
    return (
      <AppHolder style={{ background: customizedTheme.backgroundColor }}>
        <Toolbar
          style={{
            paddingLeft: '15px',
            minHeight: '64px',
            background: customizedTheme.topbarTheme,
          }}
        >
          <IconButtons
            id="topbarCollapsed"
            aria-label="open drawer"
            onClick={toggleCollapsed}
            className="right"
          >
            <Icon>menu</Icon>
          </IconButtons>
          <Link to="/dashboard" onClick={this.props.logout} className="dropdownLink">
            <img src={Logo} alt="Logo" className="logoApp"/>
          </Link>

          {renderUser(user, propsTopbar)}

        </Toolbar>
      </AppHolder>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
    user: getUserFromList(state, getUserId(state))
  }),
  { toggleCollapsed, switchActivation }
)(Topbar);
