import axios from 'axios'
import { get, post, put } from '../../../api'
import { storeError } from '../general/actions'
import { ApiEndPoint } from '../../../settings/constants'
import { DEFAULT_LANGUAGE } from '../../../settings/constants'
import { getRoute } from './reducer'
import { getImage } from '../media/reducer'
import { getToken } from '../login/reducer'

const actions = {
  FETCH_ROUTES: "FETCH_ROUTES",
  FETCH_ROUTE: "FETCH_ROUTE",
  FETCH_ROUTE_ITEMS: "FETCH_ROUTE_ITEMS",
  ADD_ROUTE: "ADD_ROUTE",
  EDIT_ROUTE: "EDIT_ROUTE",
  REMOVE_ROUTE: "REMOVE_ROUTE",
  CLEAR_ROUTE: "CLEAR_ROUTE",
  clearRoute: () => {
    return { type: actions.CLEAR_ROUTE };
  },
  fetchRoute: (routeId, updateList = false) => {
    return function (dispatch, getState) {
      const onSuccess = (data) => { return _fetchRoute(data, updateList) }
      get(`${ApiEndPoint}/routes/${routeId}`, null, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  fetchRoutes: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/routes`, null, _fetchRoutes, storeError, false)(dispatch, getState)
    }
  },
  fetchRouteItems: () => {
    return function (dispatch, getState) {
      get(`${ApiEndPoint}/routes`, null, _fetchRouteItems, storeError, false)(dispatch, getState)
    }
  },
  addRoute: (values) => {
    return function (dispatch, getState) {
      const route = { ...values, language: DEFAULT_LANGUAGE, creation_date: Date.now(), type: 'route' }
      const onSuccess = (data) => { return [_fetchRoute(data, true)] }
      post(`${ApiEndPoint}/routes`, route, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  modifyRoute: (values) => {
    return function (dispatch, getState) {
      const route = { ...values, update_date: Date.now() }
      const onSuccess = (data) => { return [_fetchRoute(data, true)] }
      put(`${ApiEndPoint}/routes/${route.id}`, route, onSuccess, storeError)(dispatch, getState)
    }
  },
  modifyRouteDefaultLanguage: (language) => {
    return function (dispatch, getState) {
      let route = getRoute(getState())
      route.language = language
      if (route.multilanguage) {
        delete (route.multilanguage[language])
      }
      actions.modifyRoute(route)(dispatch, getState)
    }
  },
  modifyRouteHeader: (title, content, permalink) => {
    return function (dispatch, getState) {
      let route = getRoute(getState())
      route = { ...route, title, content, permalink }
      actions.modifyRoute(route)(dispatch, getState)
    }
  },
  modifyRouteImage: () => {
    return function (dispatch, getState) {
      let route = getRoute(getState())
      let images = getImage(getState())
      route = { ...route, images }
      actions.modifyRoute(route)(dispatch, getState)
    }
  },
  modifyRouteLanguage: (language, translations) => {
    return function (dispatch, getState) {
      const route = getRoute(getState())
      if (!route.multilanguage) route.multilanguage = {}
      route.multilanguage[language] = { ...translations }
      actions.modifyRoute(route)(dispatch, getState)
    }
  },
  addTaxonomyToRoute: (taxonomyId) => {
    return function (dispatch, getState) {
      const route = getRoute(getState())
      route.categories ? route.categories.push(taxonomyId) : route.categories = [taxonomyId]
      actions.modifyRoute(route)(dispatch, getState)
    }
  },
  removeRoute: (id) => {
    return function (dispatch, getState) {
      const access_token = getToken(getState())
      axios.delete(`${ApiEndPoint}/routes/${id}?access_token=${access_token}`)
        .then(function () {
          dispatch(_removeRoute(id))
        })
    }
  },
}

function _fetchRoute (data, updateList) { return { type: actions.FETCH_ROUTE, updateList, payload: data } }
function _fetchRoutes (data) { return { type: actions.FETCH_ROUTES, payload: data } }
function _fetchRouteItems (data) { return { type: actions.FETCH_ROUTE_ITEMS, payload: data } }
function _removeRoute (id) { return { type: actions.REMOVE_ROUTE, payload: id } }

export default actions