import axios from 'axios';
import { get, post, put } from '../../../api'
import { storeError } from '../general/actions'
import { ApiEndPoint } from '../../../settings/constants'
import { DEFAULT_LANGUAGE } from '../../../settings/constants'
import { getEvent } from './reducer'
import { getImage } from '../media/reducer'
import { getToken } from '../login/reducer'

const actions = { 
  FETCH_EVENTS: "FETCH_EVENTS",
  REMOVE_MANY_EVENTS: "REMOVE_MANY_EVENTS",
  FETCH_EVENT: "FETCH_EVENT",
  ADD_EVENT: "ADD_EVENT",
  EDIT_EVENT: "EDIT_EVENT",
  REMOVE_EVENT: "REMOVE_EVENT",
  CLEAR_EVENT: "CLEAR_EVENT",
  RESET_COUNTERS: "RESET_COUNTERS",
  COUNT_EVENT_TREATED: "COUNT_EVENT_TREATED",
  COUNT_EVENT_CREATED: "COUNT_EVENT_CREATED",
  COUNT_EVENT_DUPLICATED: "COUNT_EVENT_DUPLICATED",
  clearEvent: () => {
    return { type: actions.CLEAR_EVENT };
  },
  fetchEvent: (eventId, updateList = true) => {
    return function (dispatch, getState) {
      const onSuccess = (data) => { return _fetchEvent(data, updateList) }
      get(`${ApiEndPoint}/events/${eventId}`, null, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  fetchEvents: (active = true) => {
    return function (dispatch, getState) {
      if (active) {
        var now = new Date().setHours(0, 0, 0, 0);
        get(`${ApiEndPoint}/events?filter[where][from_date][gt]=${now}&filter[order]=id%20DESC`, null, _fetchEvents, storeError, false)(dispatch, getState)
      } else {
        get(`${ApiEndPoint}/events?filter[order]=id%20DESC`, null, _fetchEvents, storeError, false)(dispatch, getState)
      }
    }
  },
  deleteManyEvents: (eventIds) => {
    return function (dispatch, getState) {
      post(`${ApiEndPoint}/events/deleteManyEvents`, {ids: eventIds}, _removeManyEvents, storeError, false)(dispatch, getState)
    }
  },
  findEvents: (searchParameters) => {
    return function (dispatch, getState) {
      const parameters = {}
      if (searchParameters.title) {
        parameters.title = {
          like: searchParameters.title,
          options: "i"
        }
      }
      if (searchParameters.location) {
        parameters.locations = { in: [searchParameters.location] }
        }
      if (searchParameters.landmark) {
        parameters.landmark_id = { "regexp": searchParameters.landmark }
      }
      if (searchParameters.language) {
        parameters.language = { "eq": searchParameters.language }
      }
      if (searchParameters.category) {
        parameters._categories = { "regexp": searchParameters.category }
      }
      if (searchParameters.status) {
        parameters.status = { "regexp": searchParameters.status }
      }
      if (searchParameters.dateFrom && searchParameters.dateTo) {
        if (searchParameters.dateType === 'C') {
          parameters.creation_date = { between: [searchParameters.dateFrom.getTime(), searchParameters.dateTo.getTime()] }
        }
        if (searchParameters.dateType === 'I') {
          parameters.from_date = { between: [searchParameters.dateFrom.getTime(), searchParameters.dateTo.getTime()] }
        }
      }
      if (searchParameters.isFeatured !== '') {
        parameters.is_featured = searchParameters.isFeatured
      }
      console.log('parameters', JSON.stringify({ "where": { ...parameters } }))
      get(`${ApiEndPoint}/events`, { "filter": { "where": { ...parameters }, "order": 'id DESC' } }, _fetchEvents, storeError, false)(dispatch, getState)
    }
  },
  resetCounters: (integrationId) => {
    return function (dispatch) {
      dispatch({ type: actions.RESET_COUNTERS, payload: integrationId })
    }
  },
  addEventFromIntegration: (integrationId, values) => {
    if (!values.integration_hash) {
      //console.log('NO INTEGRATION_HASH', values)
      //console.log('NO INTEGRATION_HASH ID', integrationId)
    }
    ////console.log('IntegrationId -> ', integrationId)
    ////console.log('values -> ', values)
    return function (dispatch, getState) {
      dispatch({ type: actions.COUNT_EVENT_TREATED, payload: integrationId })
      const access_token = getToken(getState())
      const url = `${ApiEndPoint}/events/?access_token=${access_token}&filter[where][integration_hash]=${values.integration_hash}&filter[limit]=1`
      axios.get(url)
        .then(function (response) {
          if (response.data) {
            //console.log('@action addEventFromIntegration', values)
          }
          if (response.data && response.data.length > 0) {
            dispatch({ type: actions.COUNT_EVENT_DUPLICATED, payload: integrationId })
          } else {
            dispatch({ type: actions.COUNT_EVENT_CREATED, payload: integrationId })
            actions.addEvent(values, true)(dispatch, getState)
          }
        })
        .catch(function (error) {
          //console.log('error al comprobar evento duplicado', error);
          return false
        })
    }
  },
  addEvent: (values, isIntegration) => {
    //values -> { objeto con propiedades de registro de Evento }
    return function (dispatch, getState) {
      if (values.from_date) values.from_date = new Date(values.from_date).getTime()
      if (values.to_date) values.to_date = new Date(values.to_date).getTime()
      //SETS DEFAULT LANGUAGE
      if (!isIntegration) {
        values.language = DEFAULT_LANGUAGE
      }
      const event = { ...values, creation_date: Date.now(), type: 'event' }
      const onSuccess = event => { return [_fetchEvent(event, true)] }
      const onSuccessIntegration = event => { return [actions.getImageFromIntegration(event)] }
      //post(`${ApiEndPoint}/events`, event, onSuccess, storeError, false)(dispatch, getState)
      if (isIntegration) {
        post(`${ApiEndPoint}/events`, event, onSuccessIntegration, storeError, false)(dispatch, getState)
      } else {
        post(`${ApiEndPoint}/events`, event, onSuccess, storeError, false)(dispatch, getState)
      }
    }
  },
  getImageFromIntegration: (data) => {
    // En teoria en data ya viene un event ID, ya que es la respuesta del post de evento.
    return function (dispatch, getState) {
      let imageUrl = data.images.image_large_url
      getBase64(imageUrl)
        .then(resp => {
          let base64String = 'data:image/' + getImageType(imageUrl) + ';base64,' + resp
          fetch(base64String)
            .then(res => res.blob())
            .then(blob => {
              let filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1)
              let imageType = setMimeType(filename)
              let file = new File([blob], filename, {
                type: imageType,
              })
              //console.log('INTENTO LLAMADA A @uploadImage')
              return actions.uploadImageFromIntegration(data, file)(dispatch, getState)
            })
        })
    }
  },
  uploadImageFromIntegration: (event, image) => {
    return function (dispatch, getState) {
      //console.log('EVENT ->', event)
      //console.log('IMAGE ->', image)
      const formData = new FormData()
      formData.append('file', image)
      //console.log('EVENT ID', event.id)
      const onSuccess = (imageUrls) => {  return changeEventImageUrls(event, imageUrls)(dispatch, getState) }
      post(`${ApiEndPoint}/images/${event.id}/shapeAndUpload`, formData, onSuccess, storeError, false)(dispatch, getState)
    }
  },
  modifyEvent: (values) => {
    //console.log('modifyEvent',values);
    return function (dispatch, getState) {
      if (!values.has_time) {
        if (values.from_date) values.from_date = new Date(values.from_date).setHours(0, 0, 0, 0)
        if (values.to_date) values.to_date = new Date(values.to_date).setHours(0, 0, 0, 0)
      }
      if (values.from_date) values.from_date = new Date(values.from_date).getTime()
      if (values.to_date) values.to_date = new Date(values.to_date).getTime()
      const event = { ...values, update_date: Date.now() }
      const onSuccess = (data) => { return [_fetchEvent(data, true)] }
      put(`${ApiEndPoint}/events/${event.id}`, event, onSuccess, storeError)(dispatch, getState)
    }
  },
  modifyEventDefaultLanguage: (language) => {
    return function (dispatch, getState) {
      let event = getEvent(getState())
      if (!event.multilanguage) event.multilanguage = {}
      event.multilanguage[event.language] = {
        title: event.title,
        content: event.content,
        permalink: event.permalink,
      }
      delete event.multilanguage[language]
      event.language = language
      actions.modifyEvent(event)(dispatch, getState)
    }
  },
  modifyEventHeader: (title, content, permalink) => {
    return function (dispatch, getState) {
      let event = getEvent(getState())
      event = { ...event, title, content, permalink }
      actions.modifyEvent(event)(dispatch, getState)
    }
  },
  modifyEventImage: (imagesObject) => {
    //La propiedad imagesObject solo viene desde @src/containers/common/images.js > updateImageWithClipBoardUrl(), todas las demás llamadas no vendrán con parámetro
    console.log('imagesObject',imagesObject);
    return function (dispatch, getState) {
      
      const event = getEvent(getState())
      console.log('getState',event);
      let images = null
      if (imagesObject) {
        images = imagesObject
        console.log('imagesObject', images)
      } else if(event?.images) {
        images = event.images
        console.log('getImage(getState())', images)
      } else {
        images = getImage(getState())
        console.log('getImage(getState())', images)

      }


      let mergedEvent = { ...event, images }
      console.log('mergedEvent', mergedEvent);

      console.log("hay event e images",event, images)
      //Al crear un evento nuevo, al llegar aquí, no viene ningún evento por lo que la petición PUT a events/eventId no funciona
      if(images){
        console.log('mergedEvent images', mergedEvent);

        actions.modifyEvent(mergedEvent)(dispatch, getState)
      }else if(event){
        console.log('mergedEvent event', mergedEvent);
        actions.modifyEvent(mergedEvent)(dispatch, getState)
        //doNothing(dispatch,getState)
      }

      else {}
    }
  },
  addTaxonomyToEvent: (taxonomyId) => {
    return function (dispatch, getState) {
      const event = getEvent(getState())
      event.categories ? event.categories.push(taxonomyId) : event.categories = [taxonomyId]
      actions.modifyEvent(event)(dispatch, getState)
    }
  },
  removeTaxonomyFromEvent: (taxonomyId) => {
    return function (dispatch, getState) {
      const event = getEvent(getState())
      event.categories = event.categories.filter(id => id !== taxonomyId)
      actions.modifyEvent(event)(dispatch, getState)
    }
  },
  modifyEventLandmark: (landmarkId) => {
    return function (dispatch, getState) {
      const event = getEvent(getState())
      event.landmark_id = landmarkId
      actions.modifyEvent(event)(dispatch, getState)
    }
  },
  modifyEventLocations: (locations) => {
    return function (dispatch, getState) {
      const event = getEvent(getState())
      event.locations = locations
      actions.modifyEvent(event)(dispatch, getState)
    }
  },
  modifyEventLanguage: (language, translations) => {
    return function (dispatch, getState) {
      const event = getEvent(getState())
      if (!event.multilanguage) event.multilanguage = {}
      event.multilanguage[language] = { ...translations }
      actions.modifyEvent(event)(dispatch, getState)
    }
  },
  removeEvent: (eventId) => {
    return function (dispatch, getState) {
      const access_token = getToken(getState())
      axios.delete(`${ApiEndPoint}/events/${eventId}?access_token=${access_token}`)
        .then(function () {
          dispatch(_removeEvent(eventId))
        })
    }
  }
}

function getImageType(imageUrl) {
  // Devuelve el tipo de la imagen (png, jpeg, jpg ...
  let extensionDot = null
  let paramToRemove = null
  if (imageUrl.includes('?')) {
    let extensionDot = imageUrl.lastIndexOf('.') + 1
    let paramToRemove = imageUrl.indexOf('?')
    //console.log('EXTENSION', imageUrl.substring(extensionDot, paramToRemove))
    return imageUrl.substring(extensionDot, paramToRemove)
  } else {
    //console.log('EXTENSION', imageUrl.substring(imageUrl.lastIndexOf('.') + 1))
    return imageUrl.substring(imageUrl.lastIndexOf('.') + 1)
  }
  
}

function getBase64(url) {
  return axios
    .get(url, {
      responseType: 'arraybuffer'
    })
    .then(response => Buffer.from(response.data, 'binary').toString('base64'))
}

function setMimeType(image) {
  let imageExtension = getImageType(image)
  imageExtension = imageExtension.toLowerCase()
  if (imageExtension === 'jpg' || imageExtension === 'jpeg') {
    return 'image/jpeg'
  } else if (imageExtension === 'png') {
    return 'image/png'
  } else if (imageExtension === 'gif') {
    return 'image/gif'
  } else if (imageExtension === 'svg') {
    return 'image/svg+xml'
  }
  return ''
}

function changeEventImageUrls(event, imageUrls) {
  //Las propiedades de imageUrls son { result: { large: 'url', medium: 'url', thumbnail:'url'}}
  //console.log('changeEventImageUrls', imageUrls)
  return function (dispatch, getState) {
    event.images.image_thumbnail_url = imageUrls.result.thumbnail
    event.images.image_medium_url = imageUrls.result.medium
    event.images.image_large_url = imageUrls.result.large
    event.images.image_medium_large_url = imageUrls.result.medium
    actions.modifyEvent(event)(dispatch, getState)
  }
}

function _fetchEvent(data, updateList) { return { type: actions.FETCH_EVENT, updateList, payload: data } }
function _fetchEvents(data) { return { type: actions.FETCH_EVENTS, payload: data } }
function _removeEvent(id) { return { type: actions.REMOVE_EVENT, payload: id } }
function _removeManyEvents(ids) { return { type: actions.REMOVE_MANY_EVENTS, payload: ids}}

export default actions;