import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import IntlMessages from '../components/utility/intlMessages'
import { FormattedMessage } from 'react-intl';

//export const ApiEndPoint = 'http://localhost:8080/api'; //Local
//export const ApiEndPoint = 'http://api.k-lenda.clientes.iguanadev.com/api'; //Amazon PRO
export const ApiEndPoint = 'https://api.k-lenda.com/api'; //Amazon PRO
export const AWSMediaBucket = 'k-lenda.com.media'
export const GOOGLE_API_KEY = 'AIzaSyAV0vViCZ0ltzdw-jvsjoV8hiI4qyOxDzo'

export const ADMIN = 'admin'
export const TRANSLATOR = 'translator'

export const TOKEN_TTL_DAYS = 1
export const ROWS_PER_PAGE = 25
export const ROWS_PER_PAGE_OPTIONS = [25,50,100]
export const DEFAULT_LANGUAGE = 'en'
export const LANGUAGES = ['en', 'es', 'ca', 'de', 'it', 'fr']
export const STATUSES = ['draft', 'pending', 'publish']
export const ALL = 'all'
export const NONE = 'none'

/* Acciones de log */
export const LOG_ELEMENTTYPES = {
  WIDGET: 'widget'
}

/* Acciones de log */
export const LOG_ACTIONS = {
  WIDGET_INIT: 'widget_init'
}

/* Formato de fecha con día */
export const DAY_FORMAT = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit'
}

/* Formato de fecha con día y hora */
export const HOUR_FORMAT = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
}

export function getLanguageItems(addAll = false) {
  const items = []
  if (addAll) {
    items.push(<MenuItem key={ALL} value={ALL}><IntlMessages id={'common.all'} /></MenuItem>)
  }
  for (let language of LANGUAGES) {
      items.push(<MenuItem key={language} value={language}><IntlMessages id={'common.language.' + language}/></MenuItem>)
    } 
  return items
}

export function getStatusItems(addAll = false) {
  const items = []
  if (addAll) {
    items.push(<MenuItem key={ALL} value={ALL}><IntlMessages id={'common.all'} /></MenuItem>)
  }
  for (let status of STATUSES) {
    items.push(<MenuItem key={status} value={status}><IntlMessages id={'status.' + status} /></MenuItem>)
  }
  return items
}

export const TABLE_OPTIONS = {
  textLabels: {
    body: {
      noMatch: <FormattedMessage id='table.noData' />,
      toolTip: <FormattedMessage id='table.order' />
    },
    pagination: {
      next: <FormattedMessage id='table.nextPage' />,
      previous: <FormattedMessage id='table.prevPage' />,
      rowsPerPage: <FormattedMessage id='table.rowsPerPage' />,
      displayRows: ' / '
    },
    toolbar: {
      search: <FormattedMessage id='general.search' />,
      downloadCsv: <FormattedMessage id='table.downloadCsv' />,
      print: <FormattedMessage id='table.print' />,
      viewColumns: <FormattedMessage id='table.seeColumns' />,
      filterTable: <FormattedMessage id='table.filter' />
    },
    filter: {
      all: <FormattedMessage id='table.all' />,
      title: <FormattedMessage id='table.filters' />,
      reset: <FormattedMessage id='table.reset' />
    },
    viewColumns: {
      title: <FormattedMessage id='table.showColumns' />,
      titleAria: <FormattedMessage id='table.showOrHideColumns' />
    },
    selectedRows: {
      text: <FormattedMessage id='table.selectedRows' />,
      delete: <FormattedMessage id='table.delete' />,
      deleteAria: <FormattedMessage id='table.deleteSelectedRows' />
    }
  }
}
