import { Map } from 'immutable'
import actions from './actions'

const initState = new Map({
  widgets: null
});

export default function widgetsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_WIDGET:
      return _setWidget(state, action.payload, action.updateList)
    case actions.CLEAR_WIDGET:
      return state.set('widget', null);
    case actions.FETCH_WIDGETS:
      return state.set('widgets', action.payload)
    case actions.REMOVE_WIDGET:
      return _deleteElement(state, action.payload)
    default:
      return state;
  }
}

function _setWidget (state, item, updateList) {
  const items = JSON.parse(JSON.stringify(state.get('widgets')))
  if (updateList && items) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === item.id)
    if (foundIndex > -1) {
      items[foundIndex] = item
    } else {
      items.push(item)
    }
    return state.set('widget', item).set('widgets', items)
  } else {
    return state.set('widget', item)
  }
}

function _deleteElement (state, id) {
  const items = JSON.parse(JSON.stringify(state.get('widgets')))
  if (items && id) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === id)
    if (foundIndex > -1) {
      items.splice(foundIndex, 1)
    }
    return state.set('widgets', items)
  }
}

export function getWidgets (state) {
  return state.Widgets.get('widgets')
}

export function getClientWidgets (state, clientId) {
  const widgets = state.Widgets.get('widgets')
  if (widgets) {
    return widgets.filter((widget) => widget.client_id === clientId)
  }
}

export function getWidget (state) {
  return state.Widgets.get('widget')
}

export function getWidgetFromList (state, widgetId) {
  const widgets = getWidgets(state)
  if (widgets) {
    var map = widgets
    var widget = map.find(function(widget) { return widget.get('id') === widgetId })
    return widget
  }
  return null
}