import { Map } from 'immutable'
import { FETCH_COUNTER } from './actions'

/**
 * Reducer para el almacenamiento de datos de logs de la aplicación.
 *
 * @param {Object} state - estado de redux
 * @param {Object} action - acción a ejecutar
 */
export default function (state = new Map(), action) {
  switch (action.type) {
    case FETCH_COUNTER:
      return state
        .setIn(['count', action.elementType, action.elementId, action.action], action.payload)
  }

  return state
}

/**
 * Devuelve el contador de logs correspondiente a los parámetros indicados
 *
 * @param {Object} state - Estado general
 * @return {string} - id de usuario
 */
export function getCount (state, elementType, elementId, action) {
  if (state.Log) {
    return state.Log.getIn(['count', elementType, elementId, action])
  }
}
