import React, { Component, lazy, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

import Route from '../../components/utility/customRoute';
import Loader from '../../components/utility/Loader/';
import { ADMIN, TRANSLATOR } from '../../settings/constants';

const PrivateRoute = ({ user, permits, component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    (!user || (!permits || permits.includes(user.role)))
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
)

export const routes = [
  {
    path: '',
    component: lazy(() => import('../Dashboard')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'events',
    component: asyncComponent(() => import('../Posts/Events')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'event-categories',
    component: asyncComponent(() => import('../Taxonomies/Events')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'event-categories/edit',
    component: asyncComponent(() => import('../Taxonomies/Events/edit')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'landmarks',
    component: asyncComponent(() => import('../Posts/Landmarks')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'landmark-categories',
    component: asyncComponent(() => import('../Taxonomies/Landmarks')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'landmark-categories/edit',
    component: asyncComponent(() => import('../Taxonomies/Landmarks/edit')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'routes',
    component: asyncComponent(() => import('../Posts/Routes')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'tips',
    component: asyncComponent(() => import('../Posts/Tips')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'ads',
    component: asyncComponent(() => import('../Posts/Ads')),
    permits: [ADMIN]
  },
  {
    path: 'clients',
    component: asyncComponent(() => import('../Posts/Clients')),
    permits: [ADMIN]
  },
  {
    path: 'locations',
    component: asyncComponent(() => import('../Posts/Locations')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'widgets',
    component: asyncComponent(() => import('../Posts/Widgets')),
    permits: [ADMIN]
  },
  {
    path: 'todos',
    component: asyncComponent(() => import('../Posts/Todos')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'users',
    component: asyncComponent(() => import('../Posts/Users')),
    permits: [ADMIN]
  },
  {
    path: 'route-categories',
    component: asyncComponent(() => import('../Taxonomies/Routes')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'route-categories/edit',
    component: asyncComponent(() => import('../Taxonomies/Routes/edit')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'events/edit',
    component: asyncComponent(() => import('../Posts/Events/edit')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'events/editNew',
    component: asyncComponent(() => import('../Posts/Events/edit/newEdit')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'events/translations',
    component: asyncComponent(() => import('../Translate/Events')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'routes/translations',
    component: asyncComponent(() => import('../Translate/Routes')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'landmarks/translations',
    component: asyncComponent(() => import('../Translate/Landmarks')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'tips/translations',
    component: asyncComponent(() => import('../Translate/Tips')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'ads/translations',
    component: asyncComponent(() => import('../Translate/Ads')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'event-categories/translations',
    component: asyncComponent(() => import('../Translate/Taxonomies/events')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'landmark-categories/translations',
    component: asyncComponent(() => import('../Translate/Taxonomies/landmarks')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'route-categories/translations',
    component: asyncComponent(() => import('../Translate/Taxonomies/routes')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'landmarks/edit',
    component: asyncComponent(() => import('../Posts/Landmarks/edit')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'routes/edit',
    component: asyncComponent(() => import('../Posts/Routes/edit')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'tips/edit',
    component: asyncComponent(() => import('../Posts/Tips/edit')),
    permits: [ADMIN, TRANSLATOR]
  },
  {
    path: 'ads/edit',
    component: asyncComponent(() => import('../Posts/Ads/edit')),
    permits: [ADMIN]
  },
  {
    path: 'clients/edit',
    component: asyncComponent(() => import('../Posts/Clients/edit')),
    permits: [ADMIN]
  },
  {
    path: 'widgets/edit',
    component: asyncComponent(() => import('../Posts/Widgets/edit')),
    permits: [ADMIN]
  },
  {
    path: 'todos/edit',
    component: asyncComponent(() => import('../Posts/Todos/edit')),
    permits: [ADMIN]
  },
  {
    path: 'users/edit',
    component: asyncComponent(() => import('../Posts/Users/edit')),
    permits: [ADMIN]
  },
  {
    path: 'integrations',
    component: asyncComponent(() => import('../Integrations')),
    permits: [ADMIN]
  },
  {
    path: 'reports/export',
    component: asyncComponent(() => import('../Reports/ExportEvents')),
    permits: [ADMIN]
  },
  {
    path: 'statistics',
    component: asyncComponent(() => import('../Statistics')),
    permits: [ADMIN]
  },
];

class AppRouter extends Component {

  render() {
    const { url, user, style } = this.props;
    return (
      <Suspense fallback={<Loader />}>
        <div style={style}>
          {routes.map(singleRoute => {
            const { permits, path, exact, ...otherProps } = singleRoute;
            return (
              <PrivateRoute
                exact={exact === false ? false : true}
                key={singleRoute.path}
                user={user}
                permits={permits}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </div>
      </Suspense>
    );
  }
}

export default AppRouter;
