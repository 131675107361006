import { Map } from 'immutable'
import actions from "./actions"
import { getLastModified } from '../utils'

const initState = new Map({
  tips: null
});

export default function tipsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_TIP:
    return _setTip(state, action.payload, action.updateList)
    case actions.CLEAR_TIP:
      return state.set('tip', null)
    case actions.FETCH_TIPS:
      return state.set('tips', getLastModified(action.payload))
    case actions.REMOVE_TIP:
      return _deleteElement(state, action.payload)
    default:
      return state
  }
}

function _setTip (state, item, updateList) {
  const items = JSON.parse(JSON.stringify(state.get('tips')))
  if (updateList && items) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === item.id)
    if (foundIndex > -1) {
      items[foundIndex] = item
    } else {
      items.push(item)
    }
    return state.set('tip', item).set('tips', getLastModified(items))
  } else {
    return state.set('tip', item)
  }
}

function _deleteElement (state, id) {
  const items = JSON.parse(JSON.stringify(state.get('tips')))
  if (items && id) {
    const foundIndex = items.findIndex(itemInList => itemInList.id === id)
    if (foundIndex > -1) {
      items.splice(foundIndex, 1)
    }
    return state.set('tips', getLastModified(items))
  }
}

export function getTips (state) {
  return state.Tips.get('tips')
}

export function getTip (state) {
  return state.Tips.get('tip')
}

export function getTipFromList (state, tipId) {
  const tips = getTips(state)
  if (tips) {
    var tip = tips.find(function(tip) { return tip.get('id') === tipId })
    return tip
  }
  return null
}